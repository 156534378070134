<template>
  <div>
    <div
      v-if="email"
      class="email"
    >
      <a-icon
        v-if="isConfirmedEmail"
        type="check-circle"
        class="email--confirmed"
      />
      <a
        class="copy-text"
        type="link"
        @click="handleCopy(email)"
      >
        {{ email }}
      </a>
    </div>
    <div
      v-if="mobilePhone"
      class="phone-number"
    >
      <a-icon
        v-if="isConfirmedPhone"
        type="check-circle"
        class="phone-number--confirmed"
      />
      <a
        class="copy-text"
        type="link"
        @click="handleCopy(mobilePhone)"
      >
        +{{ mobilePhone }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { message } from "ant-design-vue"
import i18n from "@/i18n"

const props = defineProps({
  email: {
    type: String,
    default: ""
  },
  isConfirmedEmail: {
    type: Boolean,
    default: false
  },
  mobilePhone: {
    type: String,
    default: ""
  },
  isConfirmedPhone: {
    type: Boolean,
    default: false
  }
})

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}
</script>

<style lang="scss" scoped>
.copy-text {
  padding: 0;
  font-size: 14px;
  height: fit-content;
}

.phone-number {
  display: flex;
  align-items: center;
  gap: 8px;

  word-break: break-all;

  &--confirmed {
    color: $green-color;
    display: inline-block;
  }
}

.email {
  display: flex;
  align-items: center;
  gap: 8px;

  word-break: break-all;

  &--confirmed {
    color: $green-color;
    display: inline-block;
  }
}
</style>
