<template>
  <a-spin
    :spinning="fetching"
    class="form"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
      layout="vertical"
    >
      <div>
        <a-form-model-item
          :label="$t('public_title.ru')"
          prop="public_title.ru"
        >
          <a-input-group compact>
            <a-input
              v-model="form.public_title.ru"
              style="width: 70%"
            />
          </a-input-group>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('public_title.uk')"
          prop="public_title.uk"
        >
          <a-input-group compact>
            <a-input
              v-model="form.public_title.uk"
              style="width: 70%"
            />
          </a-input-group>
        </a-form-model-item>
      </div>

      <div class="form__thin-block">
        <a-form-model-item
          :label="$t('brand')"
          prop="brand"
        >
          <InfiniteScrollSelect
            :value="form.brand"
            :defaultOptions="productBrands"
            :customRequest="fetchProductBrands"
            onFocusFetch
            @change="onBrandChange"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('model')"
          prop="model"
        >
          <a-input v-model="form.model" />
        </a-form-model-item>

        <a-form-model-item
          :label="`${$t('category')}:`"
          prop="category"
          required
        >
          <a-spin :spinning="isCategoriesFetching">
            <a-tree-select
              v-model="form.category.uuid"
              :treeData="categoriesTree"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              treeNodeFilterProp="title"
              showSearch
              allowClear
              @change="onCategoryChange"
            />
          </a-spin>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('type')"
          prop="type"
        >
          <InfiniteScrollSelect
            ref="typeScrollRef"
            :value="form.type"
            :customRequest="fetchProductTypes"
            :defaultOptions="productTypes"
            labelKey="full_name"
            onFocusFetch
            @change="onTypeChange"
          />
        </a-form-model-item>
      </div>

      <div class="form__description">
        <a-form-model-item prop="description.ru">
          <template #label>
            {{ `${$t("description")} (RU)` }}
          </template>

          <EditorHtml
            id="productDescriptionRu"
            ref="productDescriptionRuRef"
            v-model="form.description.ru"
            replaceVideoIframe
            :headers="[false, 4]"
          />
        </a-form-model-item>

        <a-form-model-item prop="description.ru">
          <template #label>
            {{ `${$t("description")} (UA)` }}
          </template>

          <EditorHtml
            id="productDescriptionUa"
            ref="productDescriptionUaRef"
            v-model="form.description.uk"
            replaceVideoIframe
            :headers="[false, 4]"
          />
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-spin>
</template>

<script setup>
import { reactive, ref, computed, watch, onMounted } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import EditorHtml from "@/components/Editor/EditorHtml.vue"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import useCategories from "@/composables/useCategories"

import {
  // getTranslatedText,
  fetchProductTypes,
  fetchProductBrands
} from "@/modules/Moderation/services/moderationProductsService.js"

const props = defineProps({
  product: {
    type: Object,
    default: () => ({})
  },

  fetching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["onTypeChanged", "formChanged"])

const { categoriesTree, isCategoriesFetching, getCategories } = useCategories()

const formRef = ref()
const form = reactive({
  use_public_title: true,
  public_title: {
    uk: "",
    ru: ""
  },
  brand: undefined,
  model: "",
  type: undefined,
  category: {
    uuid: ""
  },
  description: {
    uk: "",
    ru: ""
  }
})
const formWatcher = ref()

const productDescriptionRuRef = ref()
const productDescriptionUaRef = ref()

const rules = computed(() => ({
  brand: {
    required: true,
    message: i18n.t("thisFieldMustBeFilled")
  },
  public_title: {
    ru: {
      required: true,
      message: i18n.t("thisFieldMustBeFilled")
    },
    uk: {
      required: true,
      message: i18n.t("thisFieldMustBeFilled")
    }
  },
  category: [
    {
      validator: (_, value, callback) => {
        if (value.uuid) {
          callback()
          return
        }
        callback(new Error(i18n.t("thisFieldMustBeFilled")))
      }
    }
  ]
}))

const onResetForm = () => {
  setFormFields(props.product)
}

const setFormFields = async (product) => {
  formWatcher.value?.()
  if (!product.code) return setFormWatcher()

  if (product.type) {
    form.type = product.type.oid
  }

  if (product.brand) {
    form.brand = product.brand.oid
  }

  if (product.synthetic_meta?.category?.uuid) {
    form.category = product.synthetic_meta.category
  }

  form.model = product.model
  form.public_title.ru = product.public_title.ru
  form.public_title.uk = product.public_title.uk

  form.description.ru = product.description.ru
  form.description.uk = product.description.uk

  await productDescriptionRuRef.value.setContent()
  await productDescriptionUaRef.value.setContent()

  setFormWatcher()
}

const validateForm = async () => {
  return new Promise((res) => {
    formRef.value.validate((...props) => {
      res(props)
    })
  })
}

const getLocalizedFormValues = async () => {
  const [isValid, errors] = await validateForm()

  if (!isValid) {
    const emptyFields = Object.keys(errors)
      .map((field) => i18n.t(field))
      .join(", ")

    notification.error({
      message: i18n.t("generalProductFormFilledWithErrors"),
      description: `${i18n.t("fillFields")}: ${emptyFields}`
    })
    return Promise.resolve([])
  }

  const { public_title, description, ...values } = form

  const formRu = {
    ...values,
    public_title: public_title.ru,
    description: description.ru
  }
  const formUk = { public_title: public_title.uk, description: description.uk }

  return Promise.resolve([formRu, formUk])
}

const onCategoryChange = (uuid, name) => {
  if (!uuid) {
    form.category = {}
    return
  }

  const categoryName = Array.isArray(name) ? name.join("") : name

  form.category = {
    uuid,
    name: {
      uk: categoryName,
      ru: categoryName,
      en: categoryName
    }
  }
}

// TYPES
const typeScrollRef = ref()

const productTypes = computed(() =>
  props.product?.type
    ? [{ full_name: props.product.type.full_name, oid: props.product.type.oid }]
    : []
)

const onTypeChange = (value) => {
  form.type = value
  emit(
    "onTypeChanged",
    typeScrollRef.value.selectOptions.find(({ oid }) => oid === value)
  )
}

// BRAND
const productBrands = computed(() => (props.product?.brand ? [props.product?.brand] : []))

const onBrandChange = (value) => {
  form.brand = value
}

const setFormWatcher = () => {
  resetFormWatcher()
  formWatcher.value = watch(() => form, onFormChanged, { deep: true })
}

const resetFormWatcher = () => {
  formWatcher.value?.()
  formWatcher.value = undefined
}

const onFormChanged = () => {
  resetFormWatcher()
  emit("formChanged")
}

onMounted(getCategories)

defineExpose({
  getLocalizedFormValues,
  onResetForm,
  setFormWatcher
})
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  &__thin-block {
    width: 50%;

    display: flex;
    flex-flow: row wrap;
    gap: 24px;

    & > * {
      width: calc(50% - 12px);
    }
  }

  &__description {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;

    & > * {
      width: calc(50% - 12px);
    }
  }
}
</style>
