import { API_V2 } from "@/utils/HttpUtils.js"
import simpleBuildQuery from "@/utils/simpleBuildQuery.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { ADS_PREFIX } from "./commonServices.js"

export const fetchSellers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/sellers/stats/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const editSeller = (sellerId, data) => {
  return API_V2.patch(`${ADS_PREFIX}/sellers/${sellerId}/`, data)
}

export const removeSellerLabel = (sellerId) => {
  return API_V2.patch(`${ADS_PREFIX}/sellers/${sellerId}/`, {
    default_label: null,
    label_exists: false
  })
}

export const fetchSellerOrders = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/sellers/${requestParams.seller_id}/orders/${getFormatedQueryParams(
      queryParams
    )}`,
    { signal }
  )
}

export const subtractSeller = (sellerId) => {
  return API_V2.post(`${ADS_PREFIX}/sellers/${sellerId}/subtract-cpa/`)
}

export const getStatistics = (sellerId) => {
  return API_V2.get(`${ADS_PREFIX}/sellers/${sellerId}/weekly-stats/`)
}

export const getSummaryCpa = (sellerId) => {
  return API_V2.get(`${ADS_PREFIX}/sellers/${sellerId}/cpa-summary/`)
}

export const getBillingTransactions = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/sellers/${requestParams.seller_id}/billing/transactions/${getFormatedQueryParams(
      queryParams
    )}`,
    { signal }
  )
}

export const replenishBilling = (sellerId, data) => {
  return API_V2.post(`${ADS_PREFIX}/sellers/${sellerId}/billing/replenishment/`, data)
}

export const getCpaReport = (sellerSlug, data) => {
  const queryString = simpleBuildQuery(data)

  return API_V2.get(`${ADS_PREFIX}/sellers/${sellerSlug}/cpa-report/${queryString}`, {
    responseType: "blob"
  })
}
