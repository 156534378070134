import { getCategoriesTree } from "@/modules/MPAdmin/services/categoriesService"
import { ref, shallowRef } from "vue"

export default ({ selectable } = {}) => {
  const noCategoryAccess = shallowRef(false)

  const isCategoriesFetching = shallowRef(false)
  const categoriesTree = ref([])

  const formatCategoriesTree = (categories) => {
    return categories.map((category) => {
      const { name, uuid, children, id, path } = category

      return {
        title: name,
        value: uuid,
        id: String(id),
        path,
        children: children.length > 0 ? formatCategoriesTree(children) : [],
        selectable: selectable === "all" ? true : !children.length
      }
    })
  }

  const getCategories = async () => {
    try {
      isCategoriesFetching.value = true

      const { data } = await getCategoriesTree()

      categoriesTree.value = formatCategoriesTree(data.results)
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        noCategoryAccess.value = true
      } else {
        setTimeout(getCategories, 2500)
      }
    } finally {
      isCategoriesFetching.value = false
    }
  }

  return {
    noCategoryAccess,
    categoriesTree,
    isCategoriesFetching,
    getCategories
  }
}
