<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    layout="vertical"
  >
    <a-row :gutter="24">
      <a-col :span="8">
        <a-divider orientation="left">
          {{ $t("sellerInfo") }}
        </a-divider>

        <a-form-model-item
          :label="$t('companyName')"
          prop="name"
        >
          <a-input v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('owner')"
          prop="owner"
        >
          <InfiniteScrollSelect
            :value="form.owner"
            :customRequest="fetchBuyers"
            :customRequestParams="{
              is_seller_owner: false,
              is_active: true
            }"
            :defaultOptions="defaultOwnerOptions"
            valueKey="uuid"
            searchKey="mobile_phone"
            :placeholder="$t('enterPhoneNumber')"
            @change="onOwnerChange"
          >
            <template #option="{ option }">
              {{ option.mobile_phone }}
              <template v-if="option.username || option.first_name || option.last_name">
                ({{ option.username ? `${option.username} / ` : "" }}{{ option.first_name }}
                {{ option.last_name }})
              </template>
            </template>
          </InfiniteScrollSelect>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('companyManagers')"
          prop="users"
        >
          <InfiniteScrollSelect
            ref="usersSelectRef"
            mode="multiple"
            :value="form.users"
            :customRequest="fetchBuyers"
            :customRequestParams="{
              is_seller_owner: false,
              is_active: true
            }"
            :defaultOptions="defaultUsers"
            valueKey="uuid"
            searchKey="mobile_phone"
            :placeholder="$t('enterPhoneNumber')"
            @change="handleChangeUsers"
          >
            <template #option="{ option }">
              {{ option.mobile_phone }}
              <template v-if="option.username || option.first_name || option.last_name">
                ({{ option.username ? `${option.username} / ` : "" }}{{ option.first_name }}
                {{ option.last_name }})
              </template>
            </template>
          </InfiniteScrollSelect>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('publicPhoneNumber')"
          prop="mobile_phone_public"
        >
          <a-input v-model="form.mobile_phone_public" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('notifyEmail')"
          prop="notify_emails"
        >
          <a-select
            v-model="form.notify_emails"
            mode="tags"
            :placeholder="$t('notifyEmailPlaceholder')"
            :showArrow="false"
            :filterOption="false"
            :tokenSeparators="[',']"
          >
            <a-select-option
              v-for="item in form.notify_emails"
              :key="item"
            >
              {{ item }}
              <a-icon
                type="copy"
                style="cursor: pointer; font-size: 12px; margin: 0 4px"
                @click="handleCopyEmail(item)"
              />
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('sellerLogo')"
          :extra="$t('recommendedSize', { size: '100x100' })"
          prop="small_logo"
        >
          <a-upload
            name="small_logo"
            listType="picture-card"
            :showUploadList="false"
            :beforeUpload="() => false"
            accept="image/*"
            @change="onImageChange"
          >
            <a-avatar
              v-if="logoPreviewUrl"
              :src="logoPreviewUrl"
              alt="avatar"
              shape="square"
              :size="100"
            />
            <div
              v-else
              class="ant-upload-text"
            >
              {{ $t("upload") }}
            </div>
          </a-upload>
        </a-form-model-item>
      </a-col>

      <a-col :span="8">
        <a-divider orientation="left">
          {{ $t("accessRestrictions") }}
        </a-divider>

        <a-form-model-item :label="$t('offersVisibilityTitle')">
          <a-select v-model="form.offers_visibility">
            <a-select-option key="ALL">
              {{ $t("showAllOffers") }}
            </a-select-option>
            <a-select-option key="HIDDEN">
              {{ $t("hideOffers") }}
            </a-select-option>
          </a-select>

          <a-textarea
            v-model="form.hidden_offers_message"
            :style="{ marginTop: '10px' }"
            :placeholder="$t('textOnSellerPage')"
            :rows="3"
            :disabled="disableHiddenOffersMessage"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-switch v-model="form.is_backoffice_available">
            <template #checkedChildren><a-icon type="check" /></template>
            <template #unCheckedChildren><a-icon type="close" /></template>
          </a-switch>
          &nbsp;{{ $t("allowAccessToSeller") }}
        </a-form-model-item>

        <a-form-model-item>
          <a-switch v-model="form.is_active">
            <template #checkedChildren><a-icon type="check" /></template>
            <template #unCheckedChildren><a-icon type="close" /></template>
          </a-switch>
          &nbsp;{{ $t("sellerIsActivated") }}
        </a-form-model-item>
      </a-col>

      <a-col :span="8">
        <a-divider orientation="left">
          {{ $t("additionally") }}
        </a-divider>

        <a-form-model-item :label="$t('sellerHealth')">
          <a-select v-model="form.health">
            <a-select-option
              v-for="item in healthStatuses"
              :key="item.value"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item :label="$t('participationInTheBonusProgram')">
          <a-card size="small">
            <a-switch
              v-model="form.is_bonuses_available"
              @change="onBonusAvaliabilityChange"
            >
              <template #checkedChildren><a-icon type="check" /></template>
              <template #unCheckedChildren><a-icon type="close" /></template>
            </a-switch>
            &nbsp;{{ $t("allowGetBonuses") }}

            <a-slider
              v-model="form.max_bonuses_percent"
              :disabled="!form.is_bonuses_available"
              :step="5"
            />
            % {{ $t("maxBonuses") }}
          </a-card>
        </a-form-model-item>

        <a-form-model-item>
          <a-switch v-model="form.allow_synthetic_delivery_method">
            <template #checkedChildren><a-icon type="check" /></template>
            <template #unCheckedChildren><a-icon type="close" /></template>
          </a-switch>
          &nbsp;{{ $t("allow_synthetic_delivery_method") }}
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row>
      <a-collapse>
        <a-collapse-panel
          key="1"
          :header="$t('sellerDescription')"
        >
          <EditorHtml
            v-model="form.description"
            id="sellerDescription"
          />
        </a-collapse-panel>
      </a-collapse>
    </a-row>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue"
import { Modal, notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import EditorHtml from "@/components/Editor/EditorHtml.vue"

import useHealthStatuses from "@/composables/useHealthStatuses.js"
import { IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import { fetchBuyers } from "@/modules/MPAdmin/services/buyersService"

const props = defineProps({
  sellerData: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["fieldChange"])

const formRef = ref()
const form = reactive({
  name: undefined,
  owner: undefined,
  users: [],
  mobile_phone_public: undefined,
  notify_emails: [],
  offers_visibility: "ALL",
  hidden_offers_message: "",
  is_backoffice_available: false,
  is_active: false,
  health: 2,
  is_bonuses_available: false,
  max_bonuses_percent: 0,
  allow_synthetic_delivery_method: false,
  description: "",
  small_logo: undefined
})
const formRules = computed(() => ({
  name: [{ required: true, message: i18n.t("fieldRequire") }],
  owner: [{ required: true, message: i18n.t("fieldRequire") }],
  mobile_phone_public: [{ maxLength: 12, message: i18n.t("maxLengthError", { length: 12 }) }]
}))

const defaultOwnerOptions = computed(() => {
  return props.sellerData?.owner ? [props.sellerData.owner] : []
})

const defaultUsers = computed(() => props.sellerData?.users || [])

const { healthStatuses } = useHealthStatuses()

const onOwnerChange = (value) => {
  form.owner = value
}

const onImageChange = (event) => {
  if (event.file.type.startsWith("image")) {
    form.small_logo = event.file
  } else {
    notification.error({ message: i18n.t("unsupported_format") })
  }
}

const onBonusAvaliabilityChange = () => {
  form.max_bonuses_percent = 0
}

const logoPreviewUrl = computed(() => {
  if (form.small_logo) return URL.createObjectURL(form.small_logo)
  if (props.sellerData?.small_logo)
    return `${IMAGES_CDN}/media/assets/images/${getImagePath(props.sellerData.small_logo, "full")}`
  return ""
})
const disableHiddenOffersMessage = computed(() => {
  if (!form.offers_visibility) return true
  return form.offers_visibility === "ALL"
})

const handleCopyEmail = (email) => {
  navigator.clipboard.writeText(email)
  notification.success({ message: i18n.t("emailCopied") })
}

const usersSelectRef = ref()
const handleChangeUsers = (users) => {
  const customer = usersSelectRef.value.selectOptions.find((item) => item.uuid === users.at(-1))

  form.users = users

  if (customer && customer.seller) {
    Modal.confirm({
      title: i18n.t("applying"),
      content: i18n.t("unboundAlert", { seller: customer.seller.name }),
      onCancel: () => {
        form.users = form.users.slice(0, -1)
      }
    })
  }
}

const formWatcher = ref()
const onFormChanged = () => {
  emit("fieldChange")
  formWatcher.value?.()
}
const setWatcher = () => {
  formWatcher.value?.()
  formWatcher.value = watch(() => form, onFormChanged, { deep: true })
}
const resetForm = (data = props.sellerData) => {
  Object.keys(form).forEach((key) => {
    if (key === "small_logo") return
    if (key === "users") return
    if (key === "owner") return
    form[key] = data[key]
  })

  form.small_logo = undefined
  form.users = data?.users?.map((item) => item.uuid) || []
  form.owner = data?.owner?.uuid || undefined

  formRef.value.clearValidate()
  setWatcher()
}
const getForm = async () => {
  const isValid = await formRef.value.validate().catch((valid) => valid)
  return isValid ? form : null
}

defineExpose({
  resetForm,
  getForm
})
</script>
