<template>
  <div class="html-editor">
    <vue-editor
      v-model="content"
      ref="editorRef"
      :editor-options="toolbarOptions"
      useCustomImageHandler
      @text-change="updateValue"
      @image-added="handleUploadImage"
    />
  </div>
</template>

<script setup>
import { VueEditor, Quill } from "vue2-editor"
import { IMAGES_CDN } from "@/constants/common"
import { API } from "@/utils/HttpUtils"
import getImagePath from "@/utils/getImagePath"
import ImageResize from "quill-image-resize-module"
import VideoResize from "quill-video-resize-module2/src/VideoResize"
import notifyResponseError from "@/utils/notifyResponseError"
import { nextTick, onMounted, ref } from "vue"

Quill.register("modules/ImageResize", ImageResize)
Quill.register("modules/VideoResize", VideoResize)

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  value: {
    type: String,
    required: true
  },
  // Потрібно для заміни iframe на <youtube id="" />
  // <youtube id="" />  використовується на сторінці товару, з описа товару парситься цей тег та додається у галерею,
  // також відображається в описі
  replaceVideoIframe: {
    type: Boolean,
    default: false
  },
  headers: {
    type: Array,
    default: () => [false, 1, 2, 3, 4, 5, 6]
  },
  link: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["input", "change", "update:modelValue"])

let container = [
  [{ header: props.headers }],
  ["bold", "italic"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["image", "video"],
  ["clean"],
  ["showHtml"]
]

if (props.link) {
  container.splice(2, 0, ["link"])
}

const toolbarOptions = {
  modules: {
    toolbar: {
      container,
      handlers: {
        showHtml: () => {
          // Add active class to button
          document.querySelector(".ql-showHtml").classList.toggle("showHtmlActive")

          if (txtArea.value.style.display === "") {
            let html = txtArea.value.value

            if (html === "<p><br/></p>") {
              html = null
            } else {
              html = html.replace(new RegExp("<p><br/>", "g"), "<p>")
            }

            if (props.replaceVideoIframe) {
              html = html
                .replace(/<\/?\s?(div|span)[^>]*>/gi, "")
                .replace(/>(\s+)</g, "><")
                .replace(/<youtube id="(\S*)"[ ]*\/>/gi, (youtube, id) => {
                  return `<iframe class="ql-video" allowfullscreen="true" src="https://www.youtube.com/embed/${id}?showinfo=0" frameborder="0"></iframe>`
                })
            }

            editorRef.value.quill.pasteHTML(html)
          }

          txtArea.value.style.display = txtArea.value.style.display === "none" ? "" : "none"
        }
      }
    },
    ImageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"]
    },
    VideoResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
      tagName: "iframe" // iframe | video
    }
  }
}

const content = ref()

const editorRef = ref()
const updateValue = () => {
  let html = editorRef.value.quill.getHTML()

  if (props.replaceVideoIframe) {
    html = html.replace(/<iframe.*?embed\/(\S*)\?.*?><\/iframe>/g, (iframe, id) => {
      return `<youtube id="${id}" />`
    })
  }

  emit("input", html)
  emit("change", html)
  emit("update:modelValue", html)
}

const handleUploadImage = async (file, Editor, cursorLocation, resetUploader) => {
  try {
    const fd = new FormData()

    fd.append("images", file)

    const { data } = await API.post("/mp-admin/pages/images", fd)

    const url = data.results[0].images
    const parseUrl = `${IMAGES_CDN}/media/assets/images/${getImagePath(url, "full")}`

    Editor.insertEmbed(cursorLocation, "image", parseUrl)
    resetUploader()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const setContent = () => {
  return nextTick(() => {
    let html = props.value

    if (props.replaceVideoIframe) {
      html = html
        .replace(/<\/?\s?(div|span)[^>]*>/gi, "")
        .replace(/>(\s+)</g, "><")
        .replace(/<youtube id="(\S*)"[ ]*\/>/gi, (youtube, id) => {
          return `<iframe class="ql-video" allowfullscreen="true" src="https://www.youtube.com/embed/${id}?showinfo=0" frameborder="0"></iframe>`
        })
    }

    content.value = html
  })
}

const txtArea = ref()
onMounted(() => {
  txtArea.value = document.createElement("textarea")
  txtArea.value.style.cssText =
    "width: 100%; height: 100%; margin: 0px;background: #c9c9c9;box-sizing: border-box;font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;"

  const htmlEditor = editorRef.value.quill.addContainer("ql-custom")
  htmlEditor.appendChild(txtArea.value)

  editorRef.value.quill.on("text-change", (delta, oldDelta, source) => {
    let html = editorRef.value.quill.getHTML()

    if (props.replaceVideoIframe) {
      html = html.replace(/<iframe.*?embed\/(\S*)\?.*?><\/iframe>/g, (iframe, id) => {
        return `<youtube id="${id}" />`
      })
    }

    txtArea.value.value = html
  })

  setContent()
})

defineExpose({
  setContent
})
</script>

<style lang="scss">
// Font size from marketplace
$h1-font-size: 32px;
$h2-font-size: 24px;
$h3-font-size: 21px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 15px;

$font-size: 14px;
$font-size-small_syn: 12px;

$h1-line-height: 36px;
$h2-line-height: 32px;
$h3-line-height: 30px;
$h4-line-height: 24px;
$h5-line-height: 22px;
$h6-line-height: 22px;

$line-height-16: 16px;
$line-height-18: 18px;

$h1-padding: 0 0 16px;
$h2-padding: 0 0 16px;
$h3-padding: 0 0 16px;
$h4-padding: 0 0 16px;
$h5-padding: 0 0 16px;
$h6-padding: 0 0 16px;

$link-color: #3764be;
$link-hover: rgba(55, 100, 190, 0.8);
$link-visited: #900060;

.html-editor {
  max-width: 900px;

  .ql-editor {
    min-height: 300px;
    max-width: 900 + 24 + 24 + px;
    margin: 0 auto 0;
    font-size: 15px !important;
    line-height: 24px !important;
    padding: 24px;

    iframe {
      max-width: 627px;
      max-height: 405px;
      width: 100%;
      height: 352px !important;

      @media (orientation: landscape) {
        height: calc(800px / 1.777);
      }
    }

    b,
    strong {
      font-weight: bold;
    }

    p {
      min-height: 0.1rem !important;

      br:only-child {
        display: none;
      }
    }

    .ql-align-justify {
      text-align: justify;
    }

    a {
      font-size: 15px !important;
      text-decoration: none !important;
      color: $link-color !important;
      display: inline;
      font-weight: 500;

      &:visited {
        color: $link-color !important;
      }

      &:hover,
      &:active {
        color: $link-hover !important;
        border-color: $link-hover !important;
      }

      &--inverted {
        border-color: $link-color;
      }
    }

    h1 {
      font-size: $h1-font-size !important;
      line-height: $h1-line-height !important;
      margin-bottom: 12px !important;
    }

    h2 {
      font-size: $h2-font-size !important;
      line-height: $h2-line-height !important;
      margin-bottom: 10px !important;
      @media screen and (min-width: 992px) {
        margin-bottom: 12px !important;
      }
    }

    h3 {
      font-size: $h3-font-size !important;
      line-height: $h3-line-height !important;
      margin-bottom: 10px !important;
      @media screen and (min-width: 992px) {
        margin-bottom: 12px !important;
      }
    }

    h4 {
      font-size: $h4-font-size !important;
      line-height: $h4-line-height !important;
      margin-bottom: 10px !important;
      @media screen and (min-width: 992px) {
        margin-bottom: 12px !important;
      }
    }

    h5 {
      font-size: $h5-font-size !important;
      line-height: $h4-line-height !important;
      margin-bottom: 10px !important;
      @media screen and (min-width: 992px) {
        margin-bottom: 12px !important;
      }
    }

    h6 {
      font-size: $h6-font-size !important;
      line-height: $h6-line-height !important;
      margin-bottom: 10px !important;
      @media screen and (min-width: 992px) {
        margin-bottom: 12px !important;
      }
    }

    p,
    ul,
    ol {
      font-size: $h6-font-size !important;
    }

    p {
      @media screen and (max-width: 991px) {
        margin-bottom: 16px !important;
      }
      @media screen and (min-width: 992px) {
        margin-bottom: 16px !important;
      }
    }

    ul,
    ol {
      @media screen and (max-width: 991px) {
        padding-left: 16px !important;
        margin-bottom: 24px !important;
      }
      @media screen and (min-width: 992px) {
        padding-left: 16px !important;
        margin-bottom: 24px !important;
      }
    }

    ul li,
    ol li {
      padding: 0 !important;
      @media screen and (max-width: 991px) {
        margin-bottom: 5px !important;
      }
      @media screen and (min-width: 992px) {
        margin-bottom: 5px !important;
      }
    }
  }
  .quillWrapper {
    box-shadow: $light-shadow;
  }
  .ql-video {
    margin-bottom: 12px;
  }
}

.quillWrapper {
  line-height: normal;
}
span.ql-formats:first-child > .ql-picker,
span.ql-header.ql-picker {
  border: 1px solid #ddd;
  display: flex;
  height: 100%;
  padding: 2px;
  box-shadow: inset 0 1px 1px -1px rgba(0, 0, 0, 0.2);
  background: white;
}
.ql-toolbar.ql-snow {
  border: 1px solid #eee !important;
  background: #f5f5f58c;
  border-radius: 0 0 4px 4px;
}
.ql-container.ql-snow {
  background: #fff;
  width: 100%;
  min-height: 200px;
  border-top: 0 !important;
  border-left: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ql-stroke {
  stroke: #555d66 !important;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #555d66 !important;
}
.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.3) !important;
  font-style: normal !important;
}
.ql-editor {
  min-height: 300px;
  font-size: 15px;
  font-family: Arial;
  line-height: 24px;
}
.ql-container {
  font-size: 15px;
  font-family: Arial;
  line-height: 24px;
}
.ql-container p {
  margin-bottom: 10px;
}
.ql-formats button:hover,
.ql-formats .ql-picker:hover {
  background: #abd4ff73 !important;
}
.ql-formats .ql-header.ql-picker:hover,
.ql-formats .ql-font.ql-picker:hover {
  background: white !important;
  border-color: #b4b9be;
}
.ql-picker-label {
  display: flex !important;
}
.ql-align.ql-picker.ql-icon-picker .ql-picker-label {
  display: block !important;
}
.ql-showHtml:after {
  content: "[HTML]";
}
button.ql-showHtml {
  width: 100% !important;

  &.showHtmlActive {
    font-weight: bold;
    color: #06c;
  }
}
.ql-tooltip.ql-editing {
  top: 0 !important;
  left: 0 !important;
}
</style>
