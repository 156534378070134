export default {
  condition_new: "New",
  condition_p_discount: "Discount",
  condition_refurbished: "Refurbished",
  condition_used_5: "Used perfect",
  condition_used_4: "Used good",
  condition_used_3: "Used satisfactory",
  condition_used_2: "Used returnware",
  condition_used_1: "Used non-working",
  condition_used: "Used",
  condition_w_undetermined: "Without condition",
  toCopy: "Copy",
  copied: "Copied",
  upload: "Upload",
  uploadFile: "Upload file",
  all: "All",
  yes: "Yes",
  no: "No",
  without: "Without",
  any: "Any",
  notSet: "Not set",
  from: "From",
  to: "To",
  total: "Total",
  you: "You",
  recommendedSize: "Recommended size {size}",
  notFoundTitle: "Sorry, the page you visited does not exist.",
  //  statuses
  status: "Status",
  aim: "Aim",
  condition: "Condition",
  state: "State",
  active: "Active",
  inactive: "Inactive",
  disabled: "Disabled",
  paused: "Paused",
  allHealthValues: "All health levels",
  critical: "Critical",
  low: "Low",
  normal: "Normal",
  negative: "Negative",
  hidden: "Hidden",
  showed: "Showen",
  good: "Good",
  onAudit: "On Audit",
  canceled: "Canceled",
  canceledStatus: "Canceled",
  declined: "Declined",
  deleted: "Deleted",
  success: "Success",
  approved: "Approved",
  error: "Error",
  withErrors: "With errors",
  failed: "Failed",
  isOver: "Is over",
  willOver: "Will over",
  setted: "Setted",
  unsupportedFormat: "Unsupported format",
  selling: "Selling",
  notSelling: "Not selling",
  reserved: "Reserved",
  blocked: "Blocked",
  notSpecified: "Not specified",
  used: "Used",
  confirmed: "Confirmed",
  added: "Added",
  notFound: "Not found",
  refurbished: "Refurbished",
  p_discount: "Discount",
  noData: "No data",
  noDataShorted: "N/D",
  loading: "Loading",
  //  inStock
  inStock: "In stock",
  inStockShort: "In s.",
  outOfStock: "Out of stock",
  // product
  buyer: "Buyer",
  name: "Name",
  shortName: "Name (short)",
  nameFull: "Name (full)",
  ofSeller: "Of seller",
  payer: "Payer",
  owner: "Owner",
  nickName: "Nickname",
  shop: "Shop",
  code: "Code",
  offer: "Offers | Offer | Offers",
  offerCount: "Offers",
  offerCountShorted: "Offers",
  cs_code: "CS-code",
  p_code: "P-code",
  category: "Category",
  categoryState: "Category state",
  category_uuid: "Category",
  categoriesUpdatedSuccess: "Category list updated successfully",
  editCustomRangeFilterWarning: "Save filters before editing range",
  product_name: "Product name",
  brand: "Brand",
  logo: "Logo",
  number: "Number",
  limit: "Limits | Limit | Limits",
  editLimit: "Edit limits",
  validity: "Validity",
  count: "Count",
  countShorted: "Cnt.",
  pieces: "ps.",
  existing: "Existing",
  health: "Health",
  updatedAt: "Upd. at",
  updatedAtShort: "Upd. at.",
  actions: "Actions",
  spent: "Spent",
  sold: "Sold",
  soldSum: "Sold (sum)",
  soldWithoutCancel: "Sold (without cancelation, sum)",
  sum: "Sum",
  discount: "Discount",
  price: "Price",
  date: "Date",
  time: "Time",
  course: "Course",
  phoneNumber: "Phone Number",
  purpose: "Purpose",
  day: "Days | Day | Days",
  dayShorted: "d.",
  uah: "uah",
  month: "mon.",
  monthFull: "Months | Month | Months",
  commision: "Commision",
  balance: "Balance",
  avaliableForWriteOff: "Avaliable for write-off",
  writeOff: "Write-off",
  remain: "Remain",
  position: "Positions | Position | Positions",
  canceledPositions: "Canceled positions | Canceled position | Canceled positions",
  sales: "Sales",
  order: "Order",
  orders: "Orders",
  orderShorted: "Ord.",
  ordersLeft: "Order | Orders",
  hold: "Hold",
  cancellation: "Cancellation | Cancellations",
  commentFromManager: "Manager note",
  note: "Note",
  advertising: "Advertising",
  dateAdded: "Date add.",
  changeHistory: "Change history",
  dateCreated: "Creation date",
  clearFilters: "Clear filters",
  main: "Main",
  general: "General",
  attribute: "Attribute",
  attributes: "Attributes",
  value: "Value",
  filter: "Filter",
  filters: "Filters",
  filterName: "Filter name",
  filterType: "Filter type",
  rangesCount: "Count of ranges",
  notInFilter: "Not get into filter",
  notInAllFilters: "Not get into all ranges",
  range: "Range",
  image: "Image",
  allImages: "All images",
  gallery: "Gallery",
  changeImagesOrder: "Change the order of images",
  photo: "Photo",
  addPhoto: "Add photo",
  selectOrDragPhoto: "Select or drag photo",
  selectFiles: "Select files",
  icon: "Icon",
  description: "Description",
  type: "Type",
  types: "Types",
  text: "Text",
  hint: "Hint",
  help: "Help",
  sender: "Sender",
  receiver: "Receiver",
  url: "URL",
  model: "Model",
  products: "Products",
  withOffers: "With offers",
  size: "Size",
  aspectRatio: "Aspect ratio",
  aspectRatioOfUploadedImage: "Aspect ratio of uploaded image",
  matches: "Matches",
  noMatchesFound: "No matches found",
  language: "Language",
  coefficient: "Сoefficient",
  coefficientShorted: "Coef.",
  price_from: "Price from",
  price_to: "Price to",
  rating_from: "Rating from",
  rating_to: "Rating to",
  result: "Result",
  // actions
  add: "Add",
  addTo: "Add to",
  removeFrom: "Remove from",
  deletingConfirmation: "Want to delete?",
  update: "Update",
  updated: "Updated",
  notUpdated: "Not updated",
  search: "Search",
  searchSeller: "Search seller",
  toSearch: "Search",
  create: "Create",
  created: "Created",
  edit: "Edit",
  edited: "Edited",
  rotate: "Rotate",
  change: "Change",
  choose: "Choose",
  chooseFromList: "Choose from list",
  appoint: "Appoint",
  delete: "Delete",
  new: "New",
  debit: "Debit",
  apply: "Apply",
  applying: "Applying",
  confirmation: "Confirmation",
  toReturn: "Return",
  continue: "Continue",
  continueWithoutSaving: "Continue without saving",
  cancel: "Cancel",
  reject: "Reject",
  reindex: "Reindex",
  reset: "Reset",
  save: "Save",
  coppied: "Coppied",
  send: "Send",
  read: "Read",
  download: "Download",
  loadOnCDN: "Load on CDN",
  transfer: "Transfer",
  arrange: "Arrange",
  watch: "Watch",
  changesNotSaved: "Changes not saved.",
  changesSaved: "Changes successfully saved",
  leavePage: "Leave the page?",
  fieldMustNotBeBlank: "This field must not be blank",
  invalidDelimiter: "An invalid delimiter was detected",
  invalidCsCode: "Invalid CS code was detected",
  close: "Close",
  viewResult: "View the result",
  restore: "Restore",
  select: "Select",
  export: "Export",
  cancelChanges: "Cancel changes",
  enterCSCodes: "Enter CS-codes",
  enterCLabel: "Enter Custom label",
  findedDuplicates: "Finded duplicates: {duplicates}",
  tryAgain: "Try again",
  activate: "Activate",
  stop: "Stop",
  show: "Show",
  crop: "Crop",
  calculate: "Calculate",
  test: "Test",
  finded: "Finded",
  // tools
  slider: "Slider",
  checkbox: "Checkbox",
  rangeInput: "Input range",
  tile: "Tile",
  attention: "Attention",
  resetFilters: "Reset filters",
  currentValues: "Current values",
  newValues: "New values",
  background_color: "Background color",
  font_color: "Font color",
  // user
  user: "User",
  gender: "Gender",
  birthDate: "Date of birth",
  placeOfResidence: "Place of residence",
  referalPartner: "Referal partner",
  female: "Female",
  male: "Male",
  contacts: "Contacts",
  parent: "Parent",
  firstName: "Name",
  session: "Session",
  sessions: "Sessions",
  cartCount: "Cart",
  checkoutStartCount: "Ch-out start cnt",
  checkoutSubmitCount: "Ch-out submit cn",
  sessionsCount: "Sessions cnt",
  eventsCount: "Event cnt",
  start: "Start",
  userEvents: {
    click: "Click",
    close: "Close",
    swipe: "Swipe",
    focus: "Focus",
    input: "Input",
    submit: "Submit",
    "focus-leave": "Unfocus",
    focus_leave: "Unfocus",
    page_enter: "Page Entry",
    page_focus: "Page Focus",
    page_focus_leave: "Unfocus Page",
    page_leave: "Page Exit",
    view: "View"
  },
  trafficSource: "Traffic source",
  null_sessions_count: "Null sessions count",
  null_sessions_count_shorted: "Session 0",
  non_relevant_sessions_count: "Non relevant of price count",
  non_relevant_sessions_count_shorted: "Sessions n.p.",
  unique_pages_shorted: "Uniq. Pag.",
  unique_pages: "Unique pages",
  sessionCharacterization: {
    NON_RELEVANT_SESSION: "Non relevant of price",
    NON_RELEVANT_SESSION_TITLE: "Non relevant of price",
    NULL_SESSION: "Null session",
    NULL_SESSION_TITLE: "Null sessions"
  },
  sessionExpired: "Session expired, please log in again",
  // errors
  lengthError: "Number of characters exceeded",
  maxLengthError: "Cannot exceed {length} symbols",
  minLengthError: "Must be at least {length} symbols",
  wantedLengthError: "Must be {length} symbols",
  mustBe: "Must be",
  orLowerCased: "or",
  symbolsLowerCased: "symbols",
  emptyError: "Value is required",
  fieldRequire: "Field is required",
  alreadyInDataBaseError: "The value is already in the database",
  valueQuantityError: "Number of values exceeded",
  maxValueQuantityError: "Maximum quantity of values: {quantity}",
  repetativeValuesError: "One of the values is repeated",
  urlError: "There should be a link format",
  floatError: "Must be a numeric value format",
  numberError: "Integer value required",
  cyrillicError: "Value can not contain cyrillic",
  formatEan13Error: "Must be the EAN-13 format",
  phoneFormatError: "Must be the phone format",
  wrongGtinError: "Invalid values starting with: ",
  mustBeSameError: "The values must match",
  mustBePositivError: "The value must be positive",
  nameOrModelRequiredError: 'Be sure to fill in "Product name (series)" or "Product model"',
  dateError: "Invalid date",
  timeError: "Invalid time",
  categoryNotLast: "Category is not last",
  cancelEditingWarning: "Changes made will not be saved.",
  incorrectSumError: "Value can not be less then 0.01",
  errorOnlyNumbers: "Only numbers are allowed",
  creatingError: "Can not create",
  updatingError: "Can not update",
  filterNamesDuplicateError: "The names of the different filters must be unique",
  filterNamesEmptyError: "The names must be filled",
  rangeNamesDuplicateError: "The names of the different ranges must be unique",
  rangeValuesIncorrect: "Range values is incorrect",
  calculatingFailed: "An error occurred during the calculation",
  minNumberError: "Value can't be less then {min}",
  maxNumberError: "Value can't be more then {max}",
  createListingError: "{code} already used in another product as a cover",
  invalidEmailError: "Invalid e-mail",
  passwordsNotMatchError: "Passwords not match",
  attributeNotFound: "Attribute not found",
  imageNotSupported: "Image not supported",
  imageLoadingError: "An error occurred while loading the image",
  fileLoadingError: "An error occurred while loading the file",
  dataNotSaved: "Data not saved",
  changesWillBeLost: "Changes will be lost",
  // pagination
  recordsFrom: "Records from ",
  fromRecords: "from {count} records",
  // roles
  manager: "Manager",
  customer: "Customer",
  seller: "Seller",
  moderator: "Moderator",
  // notifications
  emailCopied: "E-mail copied",
  // Components
  usersManagement: "Users management",
  messenger: "Messenger",
  messengerModeration: "Messenger moderation",
  generalMP: "MP General",
  catalogMP: "MP Catalog",
  reviewsMP: "MP Reviews",
  sellsMP: "MP Sells",
  productCatalog: "Product",
  ADS: "ADS"
}
