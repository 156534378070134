import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { MPA_PREFIX } from "./commonService.js"
import { API_V2 } from "@/utils/HttpUtils.js"

export const fetchInvoices = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/invoices${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const createInvoice = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/invoices`, payload)
}

export const updateInvoice = ({ uuid, payload }) => {
  return API_V2.patch(`${MPA_PREFIX}/invoices/${uuid}`, payload)
}
