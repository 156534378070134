<template>
  <div
    id="app"
    style="height: 100%"
  >
    <a-config-provider :locale="uk_UA">
      <a-layout style="min-height: 100%">
        <Header :fetching="isFetching()" />

        <a-layout-content>
          <div :style="{ background: '#fff', padding: '20px', minHeight: '380px' }">
            <AntLayout>
              <Content :fetching="isFetching()">
                <div v-if="checkPermissions() === 'emptyPermissionsMeta'">
                  Metadata for route permissions is not set or wrong
                </div>
                <template v-else-if="!checkPermissions()">
                  <PageForbidden />
                </template>
                <template v-else>
                  <router-view />
                </template>
              </Content>

              <GlobalGallery ref="globalGalleryRef" />
            </AntLayout>
          </div>
        </a-layout-content>
      </a-layout>
    </a-config-provider>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, provide } from "vue"
import { mapActions, mapState, mapGetters } from "vuex"
import { API, API_MESSENGER, API_V2 } from "./utils/HttpUtils"
import usePermissions from "@/composables/usePermissions.js"
import Cookies from "js-cookie"

import Header from "@/components/Layout/Header"
import Content from "@/components/Layout/Content"
import AntLayout from "@/components/Layout/AntLayout"
import PageForbidden from "@/components/Layout/PageForbidden.vue"
import GlobalGallery from "@/components/GlobalGallery/index.vue"

import uk_UA from "ant-design-vue/lib/locale-provider/uk_UA"
import { getModerationToken, getUnreadMessagesCount } from "./modules/Messenger/messengerService"

export default {
  metaInfo: {
    title: "Synthetic ERP",
    titleTemplate: "%s - Synthetic ERP"
  },

  name: "App",

  components: {
    Header,
    Content,
    PageForbidden,
    GlobalGallery,
    AntLayout
  },

  setup() {
    const { checkRouteUrl, checkApplicationPermission, checkComponentPermission } = usePermissions()

    const globalGalleryRef = ref()
    const unreadMessagesCheckInterval = ref()

    provide("galleryRef", globalGalleryRef)

    return {
      checkRouteUrl,
      checkApplicationPermission,
      checkComponentPermission,

      globalGalleryRef,
      unreadMessagesCheckInterval,

      uk_UA
    }
  },

  computed: {
    ...mapState("Auth", {
      profileRequest: (state) => state.profileRequest,
      permissionsRequest: (state) => state.permissionsRequest,
      profile: (state) => state.profile,
      isLogged: (state) => state.isLogged
    }),
    ...mapState("Messenger", {
      unreadMessages: (state) => state.unreadMessages
    }),
    ...mapGetters("Auth", ["permissions"])
  },
  beforeMount() {
    API.interceptors.response.use(this.responseInterseptorSuccess, this.responseInterseptorError)

    API_V2.interceptors.response.use(
      this.responseInterseptorSuccess,
      this.responseInterseptorErrorToken
    )

    this.getProfile()
  },

  methods: {
    ...mapActions("Auth", ["getProfile", "setLogout", "logout", "sendRefreshToken"]),
    ...mapActions("Messenger", ["setUnreadMessages"]),

    isFetching() {
      return this.profileRequest || this.permissionsRequest
    },

    checkPermissions() {
      if (Boolean(this.$route.meta.withoutChecking)) {
        return true
      } else if (!this.$route.meta.permissionType || !this.$route.meta.permissionCode) {
        return "emptyPermissionsMeta"
      } else if (!this.$route.meta) return false

      const { permissionType, permissionCode } = this.$route.meta

      if (permissionType === "application") {
        return this.checkApplicationPermission({
          code: permissionCode,
          permissions: this.permissions
        })
      }
      if (permissionType === "component") {
        return this.checkComponentPermission({
          code: permissionCode,
          permissions: this.permissions
        })
      }
    },

    responseInterseptorSuccess(response) {
      return response
    },

    responseInterseptorError(error) {
      if (error?.response?.status !== 401) return Promise.reject(error)
      if (this.$route.path === "/login") return Promise.reject(error)

      this.goToLoginPage()
      this.setLogout()
      return Promise.reject(error)
    },

    async responseInterseptorErrorToken(error) {
      const originalRequest = error?.config
      const path = this.$route.path

      if (originalRequest?.url.endsWith("logout/")) {
        if (path !== "/login") {
          this.goToLoginPage()
        }

        return Promise.reject(error)
      }

      if (error?.config?._retry || originalRequest?.url.endsWith("refresh/")) {
        if (Cookies.get("sessionid")) {
          this.logout()
        } else if (path !== "/login") {
          this.goToLoginPage()
        }

        return Promise.reject(error)
      }

      if (error?.response?.status === 401 && path !== "/login") {
        try {
          const { access_token } = await this.sendRefreshToken()
          const bearerToken = `Bearer ${access_token}`

          API_V2.defaults.headers.common.Authorization = bearerToken
          originalRequest._retry = true
          originalRequest.headers.Authorization = bearerToken

          return API_V2(originalRequest)
        } catch {
          return Promise.reject(error)
        }
      }

      return Promise.reject(error)
    },

    async checkUnreadMessages() {
      if (!API_V2.defaults.headers.common.Authorization) return

      try {
        if (!API_MESSENGER.defaults.headers.common.Authorization) {
          const { data } = await getModerationToken()
          API_MESSENGER.defaults.headers.common["Authorization"] = `Bearer ${data.token}`
        }

        const { data } = await getUnreadMessagesCount()
        if (!data.count) return

        if (this.unreadMessages !== data.count) {
          this.setUnreadMessages(data.count)
        }
        if (this.unreadMessagesCheckInterval) return
        this.unreadMessagesCheckInterval = setInterval(() => this.checkUnreadMessages(), 5000)
      } catch {
        if (this.unreadMessagesCheckInterval) {
          clearInterval(this.unreadMessagesCheckInterval)
          this.unreadMessagesCheckInterval = undefined
        }
      }
    },

    goToLoginPage() {
      const query = {
        next: this.$router.history.current.path
      }

      this.$router.push({
        path: "/login",
        query
      })
    }
  },

  mounted() {
    if (
      !this.checkApplicationPermission({ code: "00MS01", permissions: this.permissions }) &&
      !this.checkComponentPermission({ code: "00MPA/MS02", permissions: this.permissions })
    )
      return
    this.checkUnreadMessages()
  },

  watch: {
    isLogged: {
      handler(val) {
        if (!val) return
        this.checkUnreadMessages()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/main";
</style>
