<template>
  <a-drawer
    :title="$t('massiveCLInput')"
    :visible="visible"
    :width="700"
    @close="handleClose"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      :colon="false"
      class="massive-label-form"
    >
      <a-form-model-item
        :label="$t('enterCSCodes')"
        :extra="$t('massiveCsCodesHelp')"
        prop="cs_codes"
        required
      >
        <a-textarea
          v-model="form.cs_codes"
          :autoSize="{ minRows: 2, maxRows: 20 }"
        />
      </a-form-model-item>

      <a-form-model-item
        prop="label_type"
        :label="$t('chooseForMassiveChange')"
      >
        <a-radio-group
          v-model="form.label_type"
          :options="labelOptions"
          @change="form.change_fb_pricelist = 'none'"
        />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.label_type === 'ga_default_label' || form.label_type === 'all'"
        :label="`${$t('enterCLabel')}, merch0 Google`"
        prop="ga_default_label"
      >
        <a-input v-model="form.ga_default_label" />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.label_type === 'ga_merch4'"
        :label="`${$t('enterCLabel')}, merch4 Google`"
        prop="ga_merch4"
      >
        <a-input v-model="form.ga_merch4" />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.label_type === 'fb_default_label' || form.label_type === 'all'"
        :label="`${$t('enterCLabel')}, Facebook`"
        prop="fb_default_label"
      >
        <a-input v-model="form.fb_default_label" />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.label_type === 'fb_default_label' || form.label_type === 'all'"
        prop="change_fb_pricelist"
        :label="$t('facebookPriceList')"
        required
      >
        <a-radio-group
          :options="changePriceListOptions"
          v-model="form.change_fb_pricelist"
        />
        <a-alert :message="$t(`facebookPriceListOptions.${form.change_fb_pricelist}`)" />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-footer">
      <a-button @click="handleClose">{{ $t("toReturn") }}</a-button>

      <a-button
        :loading="isFetching"
        type="primary"
        @click="handleConfirm"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"

import { bulkChangeMerchantOffers } from "@/modules/ADS/services/merchantServices.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(["cancel", "submit"])

const form = reactive({
  cs_codes: "",
  ga_default_label: null,
  ga_merch4: null,
  fb_default_label: null,
  label_type: "ga_default_label",
  change_fb_pricelist: "none"
})
const formRef = ref()
const formRules = computed(() => ({
  cs_codes: { validator: checkCSCodes },
  ga_default_label: labelRule(),
  ga_merch4: labelRule(),
  fb_default_label: labelRule(),
  label_type: { required: true, message: i18n.t("fieldMustNotBeBlank") }
}))

const isFetching = ref(false)

const labelOptions = computed(() => [
  {
    label: "merch0 Google",
    value: "ga_default_label"
  },
  {
    label: "merch4 Google",
    value: "ga_merch4"
  },
  {
    label: "Facebook",
    value: "fb_default_label"
  },
  {
    label: "merch0 Google and Facebook",
    value: "all"
  }
])

const changePriceListOptions = computed(() => [
  { label: i18n.t("facebookChangePriceList.none"), value: "none" },
  { label: i18n.t("facebookChangePriceList.add"), value: "add" },
  { label: i18n.t("facebookChangePriceList.remove"), value: "remove" }
])

const labelRule = () => {
  return [
    { required: true, message: i18n.t("fieldMustNotBeBlank") },
    { max: 100, message: i18n.t("maxLengthError", { length: 100 }) }
  ]
}

const handleClose = () => {
  formRef.value.resetFields()
  emit("cancel")
}

const handleUpdateLabel = async (csCodesArray) => {
  try {
    isFetching.value = true

    const payload = {
      cs_codes: csCodesArray
    }

    const setGa0Label = () => {
      payload.ga_default_label = form.ga_default_label || null
    }
    const setGa4Label = () => {
      payload.ga_merch4 = form.ga_merch4 || null
    }
    const setFbLabel = () => {
      payload.fb_default_label = form.fb_default_label || null

      if (form.change_fb_pricelist !== "none") {
        payload.is_facebook_ad = form.change_fb_pricelist === "add" || false
      }
    }

    if (form.label_type === "all") {
      setGa0Label()
      setFbLabel()
    } else if (form.label_type === "ga_default_label") {
      setGa0Label()
    } else if (form.label_type === "ga_merch4") {
      setGa4Label()
    } else if (form.label_type === "fb_default_label") {
      setFbLabel()
    }

    if (payload.is_facebook_ad && !payload.fb_default_label) {
      notification.error({
        message: i18n.t("facebookPriceListError")
      })
      return
    }

    const { data } = await bulkChangeMerchantOffers(payload)

    notification.success({
      message: `${i18n.t("updated")} ${data.count} ${i18n.tc("offer", data.count).toLowerCase()}`
    })

    formRef.value.resetFields()
    emit("submit")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const checkCSCodes = (_, value, callback) => {
  if (value.trim() === "") {
    callback(new Error(i18n.t("thisFieldMustBeFilled")))
  } else {
    const splitedCodes = value.split(/[\r\n, ]+/).filter((item) => item.trim() !== "")

    for (const code of splitedCodes) {
      if (
        !code.startsWith("CS") ||
        code.toUpperCase() !== code ||
        code.length < 9 ||
        code.length > 10
      ) {
        return callback(new Error(i18n.t("invalidCsCode")))
      }
    }
    callback()
  }
}

const handleConfirm = async () => {
  if (isFetching.value) return

  const isValid = await formRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  const { cs_codes: csCodes } = form
  const csCodesArray = csCodes.split(/[\r\n\t, ]+/).filter(Boolean)

  const duplicateArray = csCodesArray.filter(
    (element, index, array) => array.indexOf(element) !== index
  )

  if (duplicateArray.length) {
    return notification.warning({
      message: i18n.t("findedDuplicates", { duplicates: [...duplicateArray].join(", ") })
    })
  }

  handleUpdateLabel(csCodesArray)
}
</script>

<style lang="scss" scoped>
.massive-label-form {
  padding-bottom: 50px;
}
</style>
