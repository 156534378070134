import axios from "axios"
import getCurrentLocale from "./getCurrentLocale"

export const API = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "Accept-language": getCurrentLocale()
  }
})

export const API_V2 = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v2`,
  withCredentials: true,
  headers: {
    "Accept-language": getCurrentLocale()
  }
})

export const API_MESSENGER = axios.create({
  baseURL: `${process.env.VUE_APP_MESSENGER_URL_XHR}/api/v2`,
  withCredentials: true,
  headers: {
    "Accept-language": getCurrentLocale()
  }
})

export const API_MODERATION = axios.create({
  baseURL: `${process.env.VUE_APP_API_MODERATION_URL}/api`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "Accept-language": "ru"
  }
})
