import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "./commonService"

export const fetchSellerReviews = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/sellers-reviews${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSellerReviewById = (id) => {
  return API_V2.get(`${MPA_PREFIX}/sellers-reviews/${id}`)
}

export const updateSellerReview = (data, id) => {
  return API_V2.patch(`${MPA_PREFIX}/sellers-reviews/${id}`, data)
}
