import { reactive } from "vue"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { ADS_PREFIX } from "./commonServices"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import notifyResponseError from "@/utils/notifyResponseError"
import { API_V2 } from "@/utils/HttpUtils"

export const taskIntervals = reactive({})

const getFileChangeResult = async ({ url }) => {
  try {
    window.open(url, "_blank")

    notification.success({ message: i18n.t("massiveChangeWithFileSuccess") })
  } catch {
    notification.error({
      message: i18n.t("fileLoadingError")
    })
  }
}

export const fetchMerchantData = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/offers/stats/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchSellerById = (sellerId) => {
  return API_V2.get(`${ADS_PREFIX}/sellers/${sellerId}/`)
}

export const bulkChangeMerchantOffers = (payload) => {
  return API_V2.patch(`${ADS_PREFIX}/offers/bulk/`, payload)
}

export const searchCustomLabel = ({ params, signal }) => {
  return API_V2.get(`${ADS_PREFIX}/labels/?q=${params.search}`, {
    signal
  })
}

export const uploadGoogleLabelsFile = (data) => {
  return API_V2.post(`${ADS_PREFIX}/offers/imports/ga-custom-label/`, data)
}

export const uploadGoogleMerch4LabelsFile = (data) => {
  return API_V2.post(`${ADS_PREFIX}/offers/imports/ga-merch4/`, data)
}

export const uploadFacebookLabelsFile = (data) => {
  return API_V2.post(`${ADS_PREFIX}/offers/imports/fb-custom-label/`, data)
}

export const uploadFacebookActivityFile = (formData) => {
  return API_V2.post(`${ADS_PREFIX}/offers/imports/facebook-marks/`, formData)
}

export const uploadEkatalogActivityFile = (formData) => {
  return API_V2.post(`${ADS_PREFIX}/offers/imports/ekatalog-marks/`, formData)
}

export const checkFileChangeTask = async (task_id) => {
  taskIntervals[task_id] = setInterval(async () => {
    try {
      const { data } = await API_V2.get(`${ADS_PREFIX}/offers/imports/results/${task_id}/`)

      if (data.state === "SUCCESS") {
        getFileChangeResult(data)
        clearInterval(taskIntervals[task_id])
        taskIntervals[task_id] = undefined
      }
    } catch (error) {
      clearInterval(taskIntervals[task_id])
      notifyResponseError({ error })
    }
  }, 5000)
}

export const checkSessionCookieExpired = () => {
  const storedToken = JSON.parse(localStorage.getItem("refresh_token_mpa"))
  const now = new Date().getTime()
  if (!storedToken || now > storedToken.expiry) {
    localStorage.removeItem("refresh_token_mpa")
    return true
  }

  return false
}
