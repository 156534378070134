<template>
  <div>
    <PageHeader
      :title="$t('sellerControl')"
      :show-language-select="false"
    >
      <template #filters>
        <a-input-search
          v-model="filteredInfo.search"
          :placeholder="$t('search')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          :placeholder="`${$t('health')} ${$t('ofSeller').toLowerCase()}`"
          v-model="filteredInfo.health"
          allowClear
          @change="handleTableFilterChange"
        >
          <a-select-option :key="''">
            {{ $t("allHealthValues") }}
          </a-select-option>
          <a-select-option key="0">
            {{ $t("critical") }}
          </a-select-option>
          <a-select-option key="1">
            {{ $t("low") }}
          </a-select-option>
          <a-select-option key="2">
            {{ $t("good") }}
          </a-select-option>
        </a-select>
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("clearFilters") }}
        </a-button>

        <a-button
          type="primary"
          @click="handleOpenCreateModal"
          icon="plus"
        >
          {{ $t("createSeller") }}
        </a-button>
      </template>
    </PageHeader>

    <div class="table-wrapper">
      <a-table
        :scroll="{ x: true }"
        :columns="columns"
        :dataSource="dataSource"
        :loading="isFetching"
        :pagination="paginationInfo"
        rowKey="slug"
        @change="onTableChange"
      >
        <template #name="record">
          <a-card-meta
            :title="record.name"
            :description="record.owner?.mobile_phone"
          >
            <template #avatar>
              <a-avatar
                shape="square"
                icon="shop"
                :size="50"
                :src="getImageUrl(record.small_logo)"
              />
            </template>
          </a-card-meta>
        </template>

        <template #health="text">
          <span :style="{ color: healthStatuses[text].color }">
            {{ healthStatuses[text].title }}
          </span>
        </template>

        <template #status="text">
          <span :style="{ color: text ? '#108ee9' : 'inherit' }">
            {{ text ? $t("active") : $t("hidden") }}
          </span>
        </template>

        <template #ordersCount="text, record">
          <a-button
            type="link"
            @click="goToOrders(record.slug)"
          >
            {{ text }}
          </a-button>
        </template>

        <template #percent="text">{{ text }}%</template>

        <template #offers="text, record">{{ text }} ({{ record.offers_active_count }})</template>

        <template #actions="record">
          <a-button
            type="dashed"
            shape="circle"
            icon="edit"
            @click="handleOpenEditModal(record)"
          />
        </template>
      </a-table>
    </div>

    <CreateSellerModal
      :isOpened="createModalIsOpened"
      @created="onCreatedSeller"
      @close="createModalIsOpened = false"
    />

    <EditSellerModal
      :isOpened="editModalIsOpened"
      :defaultSellerSlug="defaultEditSellerData?.slug"
      :defaultSellerName="defaultEditSellerData?.name"
      @close="handleCloseEditModal"
      @updated="onSellerUpdated"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue"
import router from "@/router"

import PageHeader from "@/ant-components/PageHeader"
import CreateSellerModal from "./components/CreateSellerModal"
import EditSellerModal from "./components/EditSellerModal"

import useHealthStatuses from "@/composables/useHealthStatuses"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useSellersColumns from "./useSellersColumns"

import { fetchSellers } from "../../services/sellersService"
import { IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"

// metaInfo: {
//   title: "Керування продавцями"
// },

const { healthStatuses } = useHealthStatuses()
const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchSellers,
  requestParams: { changeRouterQuery: true }
})

const { columns } = useSellersColumns({ sortedInfo: computed(() => sortedInfo.value) })

const createModalIsOpened = ref(false)
const editModalIsOpened = ref(false)
const defaultEditSellerData = ref({})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const getImageUrl = (image) => {
  return image ? `${IMAGES_CDN}/media/assets/images/${getImagePath(image, "full")}` : ""
}
const goToOrders = (slug) => {
  const routeData = router.resolve({
    name: "Orders",
    query: {
      seller__slug: slug,
      ordering: "-ordered_at",
      limit: 25
    }
  })

  window.open(routeData.href, "_blank")
}
const onCreatedSeller = (data) => {
  defaultEditSellerData.value = data
  editModalIsOpened.value = true
  onTableChange()
}
const onSellerUpdated = (payload) => {
  updateTableDataRecord({ identifier: "uuid", payload })
}
const handleOpenEditModal = (data) => {
  defaultEditSellerData.value = data
  editModalIsOpened.value = true
}
const handleCloseEditModal = () => {
  editModalIsOpened.value = false
  defaultEditSellerData.value = {}
}
const handleOpenCreateModal = () => {
  createModalIsOpened.value = true
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>
