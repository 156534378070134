import { computed } from "vue"
import i18n from "@/i18n"

import getFormatedSum from "@/utils/getFormatedSum"

export default ({ sortedInfo } = {}) => {
  const columns = computed(() => {
    return [
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("creatingDate"),
        scopedSlots: {
          customRender: "dateRenderer"
        },
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
        fixed: "left",
        width: 150
      },
      {
        key: "code",
        dataIndex: "code",
        title: i18n.t("returnNumber"),
        fixed: "left",
        width: 120
      },
      {
        key: "order_code",
        dataIndex: "order_code",
        title: i18n.t("orderNumber"),
        width: 120
      },
      {
        key: "aim",
        dataIndex: "aim",
        title: i18n.t("aimReasonReturn"),
        scopedSlots: {
          customRender: "aim"
        },
        width: 250
      },
      {
        key: "seller",
        dataIndex: "seller",
        title: i18n.t("seller")
      },
      {
        key: "customer_name",
        dataIndex: "customer_name",
        title: i18n.t("customer")
      },
      {
        key: "goods_quantity",
        dataIndex: "goods_quantity",
        title: i18n.t("goodsQuantity"),
        width: 120,
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "goods_quantity" && sortedInfo?.value?.order
      },
      {
        key: "price_total",
        dataIndex: "price_total",
        title: i18n.t("sum"),
        customRender: (sum) => getFormatedSum(sum, true),
        width: 125,
        sorter: true,
        sortOrder: sortedInfo?.value?.columnKey === "price_total" && sortedInfo?.value?.order
      },
      {
        key: "bonus_discount_total",
        dataIndex: "bonus_discount_total",
        title: i18n.t("discount"),
        scopedSlots: { customRender: 'discount' },
        width: 125
      },
      {
        key: "status",
        dataIndex: "status",
        title: i18n.t("returnStatus"),
        scopedSlots: {
          customRender: "status"
        },
        width: 200
      },
      {
        key: "reason",
        dataIndex: "reason",
        title: i18n.t("returnReasons"),
        width: 300
      },
      {
        key: "actions",
        scopedSlots: {
          customRender: "actions"
        },
        fixed: "right",
        width: 40
      }
    ]
  })

  return columns
}
