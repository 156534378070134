<template>
  <a-form-model
    ref="formRef"
    :model="form"
    @submit.prevent="onSubmit"
  >
    <a-form-model-item
      prop="offer_codes"
      :help="$t('massiveCsCodesHelp')"
    >
      <a-textarea
        v-model="form.offer_codes"
        :rows="15"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        icon="plus"
        :loading="isFetching"
        htmlType="submit"
      >
        {{ $t("add") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import i18n from "@/i18n"
import { addOffersToPromoCampaign } from "@/modules/MPAdmin/services/promoCampaignsService"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import { reactive, shallowRef } from "vue"
const props = defineProps({
  promoUuid: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["close", "submit"])

const form = reactive({
  offer_codes: ""
})

const onClose = () => emit("close")

const isFetching = shallowRef(false)

const onSubmit = async () => {
  try {
    isFetching.value = true
    const payload = {
      offer_codes: form.offer_codes.split(/[\r\n\t, ]+/gm).filter(Boolean)
    }

    const { data } = await addOffersToPromoCampaign({ uuid: props.promoUuid, payload })

    notification.success({ message: i18n.t("success") })
    emit("submit", data)
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>
