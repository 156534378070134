import { API_V2 } from "@/utils/HttpUtils.js"
import { MPA_PREFIX } from "./commonService.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"

export const fetchOrders = ({ queryParams, requestParams, signal }) => {
  let query = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)
  if (!query.includes("in_cart=false")) {
    query += "&in_cart=false"
  }

  return API_V2.get(`${MPA_PREFIX}/orders${query}`, { signal })
}

export const fetchOrdersMeta = () => {
  return API_V2.get(`${MPA_PREFIX}/orders/meta`)
}

export const fetchOrderById = (orderCode) => {
  return API_V2.get(`${MPA_PREFIX}/orders/${orderCode}`)
}

export const updateOrder = ({ code, payload }) => {
  return API_V2.patch(`${MPA_PREFIX}/orders/${code}`, payload)
}

export const fetchOrderHistory = (orderCode) => {
  return API_V2.get(`${MPA_PREFIX}/orders/${orderCode}/history`)
}

export const fetchOrderNotes = (orderCode) => {
  return API_V2.get(`${MPA_PREFIX}/orders/${orderCode}/notes`)
}

export const fetchOrderReturns = (orderCode) => {
  return API_V2.get(`${MPA_PREFIX}/orders/${orderCode}/returns`)
}

export const fetchOrderTransactions = (orderCode) => {
  return API_V2.get(`${MPA_PREFIX}/orders/${orderCode}/transactions`)
}

export const cancelPayment = (orderCode) => {
  return API_V2.post(`${MPA_PREFIX}/orders/${orderCode}/cancel-payment`)
}