<template>
  <div class="edit-product-drawer">
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
      rowKey="id"
      :scroll="{ y: 400 }"
    >
      <template #photoRenderer="images">
        <TableRendererPhotoGallery
          v-if="images?.length"
          :images="images"
        />
      </template>
    </a-table>

    <a-form
      :form="editProductForm"
      class="edit-product-drawer__form"
    >
      <a-form-item :label="$t('label')">
        <a-select
          :placeholder="$t('notSet')"
          v-decorator="['label']"
          allowClear
        >
          <a-select-option
            v-for="label in labels"
            :key="label.id"
            :value="label.id"
          >
            {{ label.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label="$t('category')">
        <a-tree-select
          :treeData="categoriesTree"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          treeNodeFilterProp="title"
          :placeholder="$t('notSet')"
          showSearch
          allowClear
          v-decorator="['category']"
        />
      </a-form-item>

      <a-form-item :label="$t('moderationStatus')">
        <a-select
          :options="moderationStatuses"
          v-decorator="['moderation_status']"
        />
      </a-form-item>

      <a-form-item :label="$t('moderComment')">
        <a-textarea
          v-decorator="[
            'moderation_comment',
            { rules: [{ max: 1000, message: this.$t('maxLengthError', { length: 1000 }) }] }
          ]"
          :autoSize="{ minRows: 4, maxRows: 6 }"
        />
      </a-form-item>
    </a-form>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="fetching"
        @click="onSave"
        >{{ $t("save") }}</a-button
      >
    </div>
  </div>
</template>

<script>
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { updateProductsBulk } from "@/modules/MPAdmin/services/productsService.js"

import useProductsColumns from "../../useProductsColumns.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export default {
  components: {
    TableRendererPhotoGallery
  },

  props: {
    dataSource: {
      type: Array,
      required: true
    },
    categoriesTree: {
      type: Array,
      default: () => []
    },
    moderationStatuses: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },

  setup() {
    const { editProductsColumns: columns } = useProductsColumns()

    return {
      columns
    }
  },

  data() {
    return {
      fetching: false,

      editProductForm: this.$form.createForm(this, {
        name: "editProductForm",

        mapPropsToFields: () => {
          const { category, moderation_status, label, moderation_comment } = this.dataSource[0]

          return {
            category: this.$form.createFormField({
              value: category?.uuid || undefined
            }),

            label: this.$form.createFormField({
              value: label ? `${label?.id}` : undefined
            }),

            moderation_status: this.$form.createFormField({
              value: moderation_status || undefined
            }),

            moderation_comment: this.$form.createFormField({
              value: moderation_comment || ""
            })
          }
        }
      })
    }
  },

  methods: {
    async onSave() {
      this.editProductForm.validateFields(async (err, values) => {
        if (err) return
        this.fetching = true

        const { category, moderation_status, label, moderation_comment } = values

        const payload = this.dataSource.map((item) => {
          const obj = {
            id: item.id,
            label: label || null,
            moderation_status,
            category
          }

          if (moderation_comment.length) {
            obj.moderation_comment = moderation_comment
          }

          return obj
        })

        try {
          const { data } = await updateProductsBulk(payload)

          if (!data.meta.errors.length) {
            this.$notification.success({ message: this.$t("updated") })
            this.$emit("close")
          } else {
            notifyResponseError({ error: { response: { data: data.meta.errors } } })
          }
        } catch (error) {
          notifyResponseError({ error })
        } finally {
          this.fetching = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-product-drawer {
  padding-bottom: 40px;

  &__form > * {
    width: 300px;
  }

  &__form > :last-child {
    width: 100%;
  }
}
</style>
