<template>
  <div>
    <a-divider>
      <a-icon type="history" />
      {{ $t("changesHistory") }}
    </a-divider>
    <a-table
      :dataSource="dataHistory"
      :columns="columns"
      :pagination="false"
      size="middle"
      rowKey="created_at"
      :scroll="{ y: 300 }"
    >
      <template #dateRenderer="text">
        <TableRendererDateTime :date="text" />
      </template>

      <template #editAuthorRenderer="record">
        <div>{{ record.created_by.side }}</div>
        <div :style="{ opacity: '0.45' }">{{ record.created_by.name }}</div>
      </template>

      <template #editObjectRenderer="record, _, index">
        <div>
          <div
            v-if="getPreviousValue(index).status !== record.status"
            :style="{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }"
          >
            <div :style="{ opacity: '0.45', marginRight: '4px' }">{{ $t("status") }}:</div>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <div :style="{ whiteSpace: 'nowrap' }">
                {{ getStatus(record?.status).label }}
              </div>
              <a-popover
                v-if="record.status !== 'NEW'"
                placement="top"
              >
                <template slot="content">
                  <span v-if="getPreviousValue(index)">
                    {{ getStatus(getPreviousValue(index).status)?.label }}
                  </span>
                  <a-icon type="arrow-right" />
                  {{ getStatus(record?.status)?.label }}
                </template>
                <a-icon
                  type="info-circle"
                  :style="{ marginLeft: '14px', color: '#1890FF' }"
                />
              </a-popover>
            </div>
          </div>

          <div
            v-if="getPreviousValue(index).aim !== record.aim"
            :style="{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }"
          >
            <div :style="{ opacity: '0.45', marginRight: '4px' }">{{ $t("returnPoint") }}:</div>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <div :style="{ whiteSpace: 'nowrap' }">
                {{ aims[record.aim] }}
              </div>
              <a-popover
                v-if="index > 0"
                placement="top"
              >
                <template slot="content">
                  <span v-if="getPreviousValue(index)">
                    {{ aims[getPreviousValue(index).aim] }}
                  </span>
                  <a-icon type="arrow-right" />
                  {{ aims[record.aim] }}
                </template>
                <a-icon
                  type="info-circle"
                  :style="{ marginLeft: '14px', color: '#1890FF' }"
                />
              </a-popover>
            </div>
          </div>

          <div
            v-if="getPreviousValue(index).reason !== record.reason"
            :style="{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }"
          >
            <div :style="{ opacity: '0.45', marginRight: '4px' }">{{ $t("reason") }}:</div>
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <div>
                {{ record.reason }}
              </div>
              <a-popover
                v-if="index > 0"
                placement="top"
              >
                <template slot="content">
                  <div v-if="getPreviousValue(index)">
                    {{ getPreviousValue(index).reason }}
                  </div>
                  <a-icon type="arrow-right" />
                  {{ record.reason }}
                </template>
                <a-icon
                  type="info-circle"
                  :style="{ marginLeft: '14px', color: '#1890FF' }"
                />
              </a-popover>
            </div>
          </div>

          <template v-if="record.status === 'CANCELED_BY_SELLER'">
            <div>{{ $t("sellerComment") }}: {{ selectedReturn.reject_reason }}</div>
          </template>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { computed } from "vue"
import i18n from "@/i18n"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/"
import useMPAConsts from "@/modules/MPAdmin/constants.js"
import useReturnHistoryColumns from "./useReturnHistoryColumns"

const props = defineProps({
  dataHistory: {
    type: Array,
    default: () => []
  },

  selectedReturn: {
    type: Object,
    default: () => ({})
  }
})

const { returnStatuses } = useMPAConsts()
const columns = useReturnHistoryColumns()

const aims = computed(() => ({
  RETURN_PRODUCT_REFUNDS: i18n.t("returnProductsRefunds"),
  EXCHANGE_PRODUCT: i18n.t("exchangeProduct"),
  REPAIR_PRODUCT: i18n.t("repair")
}))

const getStatus = (status) => {
  return returnStatuses.value.find((item) => item.value === status)
}

const getPreviousValue = (index) => {
  return index ? props.dataHistory[index - 1] : ""
}
</script>
