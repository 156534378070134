<template>
  <a-form-model
    ref="formRef"
    :model="form"
    style="padding-bottom: 70px; margin-top: 30px"
  >
    <a-descriptions
      bordered
      :column="1"
      size="small"
    >
      <a-descriptions-item :label="$t('seller')">
        <a
          :href="sellerUrl"
          target="_blank"
        >
          {{ props.prototype.order?.seller?.name }}
        </a>
      </a-descriptions-item>
      <a-descriptions-item :label="$t('authorFullname')">
        <b>{{ props.prototype.author?.username }}</b>
        {{ props.prototype.author?.first_name }}
        {{ props.prototype.author?.middle_name }}
        {{ props.prototype.author?.last_name }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="props.prototype.author?.mobile_phone"
        :label="$t('phoneNumber')"
      >
        <div
          class="copy-text"
          @click="handleCopyText(props.prototype.author.mobile_phone)"
        >
          +{{ props.prototype.author.mobile_phone }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('ratingOverall')">
        <span
          :style="{ color: ratingOptions[prototype.rating_product_overall]?.color || 'inherit' }"
        >
          {{ ratingOptions[props.prototype.rating_product_overall]?.label }}
          ({{ props.prototype.rating_product_description_fits }}/{{
            props.prototype.rating_delivery
          }}/{{ props.prototype.rating_service }})
        </span>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('orderNumber')">
        {{ props.prototype.order?.code }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('comment')">
        {{ props.prototype.comment }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="props.prototype.images?.length"
        :label="$t('photo')"
      >
        <TableRendererPhotoGallery :images="props.prototype.images" />
      </a-descriptions-item>
    </a-descriptions>

    <a-form-model-item
      :label="$t('moderationStatus')"
      prop="moderation_status"
    >
      <a-select
        style="width: 100%"
        v-model="form.moderation_status"
      >
        <a-select-option
          v-for="option in moderationStatusOptions"
          :key="option.value"
          :value="option.value"
        >
          <span :style="{ color: option.color }">
            {{ option.title }}
          </span>
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      v-show="form.moderation_status === 'ACTIVE'"
      :label="$t('ordering')"
      prop="ordering"
    >
      <a-input-number
        v-model="form.ordering"
        :precision="0"
        :min="0"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        @click="handleUpdateReview"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { reactive, ref } from "vue"
import { message, notification } from "ant-design-vue"
import i18n from "@/i18n"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { updateSellerReview } from "@/modules/MPAdmin/services/sellerReviewsService"
import notifyResponseError from "@/utils/notifyResponseError"
import { MARKETPLACE } from "@/constants/common"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  },
  moderationStatusOptions: {
    type: Array,
    default: () => []
  },
  ratingOptions: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["updateReview", "close"])

const sellerUrl = `${MARKETPLACE}/seller/${props.prototype.order?.seller?.slug}/`

const formRef = ref()
const form = reactive({
  moderation_status: Object.keys(props.prototype.moderation_status)?.[0] || "",
  ordering: typeof props.prototype.ordering === "number" ? props.prototype.ordering : ""
})

const handleCopyText = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}

const isFetching = ref(false)
const handleUpdateReview = async () => {
  const isValid = await formRef.value.validate()
  if (!isValid) return

  isFetching.value = true

  const payload = {
    moderation_status: form.moderation_status
  }

  if (typeof form.ordering === "number" && form.moderation_status === "ACTIVE") {
    payload.ordering = form.ordering
  }

  try {
    const { data } = await updateSellerReview(payload, props.prototype.id)

    emit("updateReview", data)
    notification.success({ message: i18n.t("updated") })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const onClose = () => emit("close")
</script>

<style lang="scss" scoped>
.copy-text {
  cursor: pointer;

  color: $primary-color;
}
</style>
