import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"
import { computed } from "vue"

export default function useMerchantColumns() {
  const columns = computed(() => [
    {
      key: "seller.slug",
      dataIndex: "seller.slug",
      title: i18n.t("searchTags.seller_slug"),
      scopedSlots: { customRender: "slugSeller" },
      fixed: "left",
      width: 150
    },
    {
      key: "ga_default_label",
      dataIndex: "ga_default_label",
      title: i18n.t("searchTags.ga_label"),
      scopedSlots: { customRender: "customLabelRenderer" },
      fixed: "left",
      width: 140
    },

    {
      key: "ga_merch4",
      dataIndex: "ga_merch4",
      title: i18n.t("searchTags.ga_merch4"),
      scopedSlots: { customRender: "customLabelRenderer" },
      fixed: "left",
      width: 140
    },
    {
      key: "fb_default_label",
      dataIndex: "fb_default_label",
      title: i18n.t("searchTags.fb_label"),
      scopedSlots: { customRender: "customLabelRenderer" },
      width: 150
    },
    {
      key: "is_facebook_ad",
      dataIndex: "is_facebook_ad",
      title: "Fb",
      customRender: (value) => (value ? i18n.t("yes") : i18n.t("no")),
      width: 60
    },
    {
      key: "is_ecatalog_ad",
      dataIndex: "is_ecatalog_ad",
      title: "Ek",
      customRender: (value) => (value ? i18n.t("yes") : i18n.t("no")),
      width: 60
    },
    {
      key: "cs_code",
      dataIndex: "cs_code",
      title: `${i18n.t("cs_code")}, ${i18n.t("searchTags.title")}`,
      scopedSlots: { customRender: "csCodeRender" },
      width: 210
    },
    {
      dataIndex: "price",
      key: "price",
      title: `${i18n.t("price")}, ${i18n.t("uah")}`,
      customRender: (price) => getFormatedSum(price),
      sorter: true,
      width: 120
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      slots: { title: "titleInStock" },
      sorter: true,
      width: 90
    },
    {
      key: "clicks",
      dataIndex: "stats.clicks",
      slots: { title: "titleClicks" },
      sorter: true,
      width: 80
    },
    {
      key: "cost_per_click",
      dataIndex: "stats.cost_per_click",
      customRender: (cost) => getFormatedSum(cost),
      slots: { title: "titleMiddlePrice" },
      sorter: true,
      width: 80
    },
    {
      key: "cost",
      dataIndex: "stats.cost",
      customRender: (cost) => getFormatedSum(cost),
      slots: { title: "titleCost" },
      sorter: true,
      width: 85
    },
    {
      key: "conv_value",
      dataIndex: "stats.conv_value",
      customRender: (price) => getFormatedSum(price),
      slots: { title: "titleConversion" },
      sorter: true,
      width: 95
    },
    {
      key: "conv_per_cost",
      dataIndex: "stats.conv_per_cost",
      customRender: (cost) => getFormatedSum(cost),
      slots: { title: "titleConvPerCost" },
      sorter: true,
      width: 105
    },
    {
      key: "sold_count",
      dataIndex: "stats.sold_count",
      slots: { title: "titleSoldCount" },
      sorter: true,
      width: 70
    },
    {
      key: "sold_price",
      dataIndex: "stats.sold_price",
      customRender: (price) => getFormatedSum(price),
      slots: { title: "titleSoldPrice" },
      sorter: true,
      width: 105
    }
  ])

  return { columns }
}
