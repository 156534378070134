import { computed } from "vue"
import i18n from "@/i18n"

export function useStatuses() {
  const orderStatuses = computed(() => {
    return {
      NEW: {
        value: "NEW",
        title: i18n.t("orderStatuses.new"),
        color: "#52C41A"
      },
      IN_PROCESSING: {
        value: "IN_PROCESSING",
        title: i18n.t("orderStatuses.inProgress"),
        color: "#FAAD14"
      },
      SELLER_APPROVED: {
        value: "SELLER_APPROVED",
        title: i18n.t("orderStatuses.approved"),
        color: "#36CFC9"
      },
      SHIPPED: {
        value: "SHIPPED",
        title: i18n.t("orderStatuses.shipped"),
        color: "#096DD9"
      },
      SUCCESSFULLY_CLOSED: {
        value: "SUCCESSFULLY_CLOSED",
        title: i18n.t("orderStatuses.closed"),
        color: "#000000"
      },
      CANCELED_BY_SELLER: {
        value: "CANCELED_BY_SELLER",
        title: i18n.t("orderStatuses.canceledBy.seller"),
        color: "#FF4D4F"
      },
      CANCELED_BY_CUSTOMER: {
        value: "CANCELED_BY_CUSTOMER",
        title: i18n.t("orderStatuses.canceledBy.customer"),
        color: "#722ED1"
      },
      CANCELED_BY_SUPERVISOR: {
        value: "CANCELED_BY_SUPERVISOR",
        title: i18n.t("orderStatuses.canceledBy.supervisor"),
        color: "#FF4D4F"
      }
    }
  })

  const paymentStatuses = computed(() => {
    return {
      AWAITING_PAYMENT: {
        value: "AWAITING_PAYMENT",
        title: i18n.t("paidWaiting"),
        color: "#000"
      },
      CANCELED_PAYMENT: {
        value: "CANCELED_PAYMENT",
        title: i18n.t("paidCanceled"),
        color: "#FF4D4F"
      },
      PAYMENT_FAILED: {
        value: "PAYMENT_FAILED",
        title: i18n.t("paymentFailed"),
        color: "#FF4D4F"
      },
      PAID: {
        value: "PAID",
        title: i18n.t("paidOnline"),
        color: "#52C41A"
      },

      REFUND_ACCEPTED: {
        value: "REFUND_ACCEPTED",
        title: i18n.t("refundAccepted"),
        tooltip: i18n.t('refundAcceptedTooltip'),
        color: "#52C41A"
      },
      REFUND_MANUALLY: {
        value: "REFUND_MANUALLY",
        title: i18n.t("refundManually"),
        tooltip: i18n.t('refundManuallyTooltip'),
        color: "#52C41A"
      },
      REFUND_CANCELED: {
        value: "REFUND_CANCELED",
        title: i18n.t("refundCanceled"),
        tooltip: i18n.t('refundCanceledTooltip'),
        color: "#FF4D4F"
      }
    }
  })

  const orderModerationStatuses = computed(() => {
    return {
      ACTIVE: {
        value: "ACTIVE",
        title: i18n.t("ACTIVE"),
        color: "#52C41A"
      },
      ON_MODERATION: {
        value: "ON_MODERATION",
        title: i18n.t("ON_MODERATION"),
        color: "#1890FF"
      },
      MODERATION_FAILED: {
        value: "MODERATION_FAILED",
        title: i18n.t("MODERATION_FAILED"),
        color: "#FF4D4F"
      }
    }
  })

  const propositionModerationStatuses = computed(() => {
    return {
      EXPECTS_MODERATION: {
        value: "EXPECTS_MODERATION",
        title: i18n.t("EXPECTS_MODERATION"),
        color: "#1890FF"
      },
      MODERATION_IN_PROCESS: {
        value: "MODERATION_IN_PROCESS",
        title: i18n.t("MODERATION_IN_PROCESS"),
        color: "#FAAD14"
      },
      MODERATION_SUCCESS: {
        value: "MODERATION_SUCCESS",
        title: i18n.t("MODERATION_SUCCESS"),
        color: "#52C41A"
      },
      MODERATION_FAILED: {
        value: "MODERATION_FAILED",
        title: i18n.t("MODERATION_FAILED"),
        color: "#FF4D4F"
      }
    }
  })

  const isActiveStatuses = computed(() => ({
    ACTIVE: {
      label: i18n.t("active"),
      value: "true"
    },
    HIDDEN: {
      label: i18n.t("hidden"),
      value: "false"
    }
  }))

  return {
    orderStatuses,
    paymentStatuses,
    orderModerationStatuses,
    propositionModerationStatuses,
    isActiveStatuses
  }
}
