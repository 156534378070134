<template>
  <a-drawer
    :visible="isOpened"
    :title="$t('editInvoiceModalTitle', { number: invoiceData.code })"
    :width="640"
    @close="handleCancel"
    :body-style="{ paddingBottom: '80px' }"
  >
    <!-- Seller name START -->
    <a-row>
      <a-col :span="12">
        {{ $t("seller") }}
      </a-col>
      <a-col :span="12">
        <a-avatar
          shape="square"
          icon="shop"
          :src="sellerLogo"
          :size="32"
          style="margin-right: 8px"
        />
        {{ invoiceData.seller }}
      </a-col>
    </a-row>
    <!-- Seller name END -->

    <a-divider>
      {{ $t("invoiceEditInvoiceDataTitle") }}
    </a-divider>

    <!-- Invoice data START -->
    <div
      style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr; grid-row-gap: 15px"
    >
      <a-row :gutter="24">
        <a-col :span="12"> {{ $t("payer") }}: </a-col>
        <a-col :span="12">
          {{ invoiceData.payer }}
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="12"> {{ $t("sellerTypePlaceholder") }}: </a-col>
        <a-col :span="12">
          <span v-if="invoiceData.seller_type === 'FN'">{{ $t("sellerTypePib") }}</span>
          <span v-if="invoiceData.seller_type === 'IE'">{{ $t("sellerTypeFop") }}</span>
          <span v-if="invoiceData.seller_type === 'PLC'">{{ $t("sellerTypeTov") }}</span>
        </a-col>
      </a-row>

      <a-row
        v-if="!isPib"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("isVatPayerFull") }}: </a-col>
        <a-col :span="12">
          {{ invoiceData.is_vat_payer ? $t("yes") : $t("no") }}
        </a-col>
      </a-row>

      <a-row
        v-if="!isPib"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("edrpou_ipn") }}: </a-col>
        <a-col :span="12">
          {{ invoiceData.edrpou }}
          <span v-if="invoiceData.ipn && invoiceData.ipn.length > 0">
            / {{ invoiceData.ipn }}
          </span>
        </a-col>
      </a-row>

      <a-row
        v-if="invoiceData.amount_with_vat"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("invoiceSumFull") }}: </a-col>
        <a-col :span="12">
          {{ getFormatedSum(invoiceData.amount_with_vat.toFixed(2)) }}
          / {{ getFormatedSum(invoiceData.amount_without_vat.toFixed(2)) }} /
          {{ getFormatedSum(invoiceData.vat.toFixed(2)) }}
        </a-col>
      </a-row>

      <a-row
        v-if="showAddress"
        :gutter="24"
      >
        <a-col :span="12"> {{ $t("invoiceAdressFull") }}: </a-col>
        <a-col :span="12">
          <span v-if="invoiceData.postal_code">{{ invoiceData.postal_code }}</span>
          <span v-if="invoiceData.region_name">, {{ invoiceData.region_name }}&nbsp;</span>
          <span v-if="invoiceData.city_name">, {{ invoiceData.city_name }}</span>
          <span v-if="invoiceData.street_name">, {{ invoiceData.street_name }}</span>
          <span v-if="invoiceData.house_number">, {{ invoiceData.house_number }}</span>
          <span v-if="invoiceData.cabinet">, {{ invoiceData.cabinet }}</span>
        </a-col>
      </a-row>
    </div>
    <!-- Invoice data END -->

    <a-divider>
      {{ $t("invoiceStatus") }}
    </a-divider>

    <!-- Paid invoice data START -->
    <div v-if="invoiceData.status === 'PAID'">
      <a-row>
        <a-col :span="12"> {{ $t("invoiceStatus") }}: </a-col>
        <a-col
          :span="12"
          style="color: #52c41a"
        >
          {{ $t("paid") }}
        </a-col>
      </a-row>

      <a-divider>
        {{ $t("invoiceManagerDataDivider") }}
      </a-divider>

      <div
        style="
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          grid-row-gap: 15px;
          margin-bottom: 15px;
        "
      >
        <a-row>
          <a-col :span="12"> {{ $t("invoicePaymentDateTime") }}: </a-col>
          <a-col :span="12">
            {{ getFormatedDateTime(invoiceData.paid_at) }}
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12"> {{ $t("invoicePaidSum") }}: </a-col>
          <a-col :span="12"> {{ getFormatedSum(invoiceData.amount_with_vat) }} грн </a-col>
        </a-row>

        <a-row>
          <a-col :span="12"> {{ $t("invoiceNumber") }}: </a-col>
          <a-col :span="12">
            {{ invoiceData.code }}
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12"> {{ $t("payerFactual") }}: </a-col>
          <a-col :span="12">
            {{ invoiceData.payer_factual }}
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- Paid invoice data END -->

    <!-- Invoice form START -->
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="formRules"
      labelAlign="left"
      :label-col="{ span: 12 }"
      :wrapper-col="{ span: 12 }"
    >
      <!-- Show when invoice status 'PAID' after change in select, not when default invoice status is equal to 'PAID' -->
      <div v-if="invoiceData.status !== 'PAID'">
        <a-form-model-item
          :label="$t('invoiceStatus')"
          prop="status"
        >
          <a-select
            v-model="form.status"
            autocomplete="off"
          >
            <a-select-option key="NEW">
              <StatusRenderer status="NEW" />
            </a-select-option>
            <a-select-option key="CANCELED">
              <StatusRenderer status="CANCELED" />
            </a-select-option>
            <a-select-option key="REMOVED">
              <StatusRenderer status="REMOVED" />
            </a-select-option>
            <a-select-option key="PAID">
              <StatusRenderer status="PAID" />
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>

      <!-- Show when invoice status 'PAID' after change in select, not when default invoice status is equal to 'PAID' -->
      <div v-if="form.status === 'PAID' && invoiceData.status !== 'PAID'">
        <!-- Paid datetime START -->
        <a-form-model-item
          :label="$t('invoicePaymentDateTime')"
          prop="paid_at"
        >
          <a-input
            v-model="form.paid_at"
            autocomplete="off"
            placeholder="DD.MM.YYYY HH:mm"
            @paste="onPaste"
          />
        </a-form-model-item>
        <!-- Paid datetime END -->

        <!-- Payment sum START -->
        <a-form-model-item
          :label="$t('invoicePaidSum')"
          prop="payment_amount"
        >
          <a-input
            v-model="form.payment_amount"
            autocomplete="off"
            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')"
            :precision="2"
            decimal-separator=","
            style="width: 100%"
            @paste="onPaste"
          />
        </a-form-model-item>
        <!-- Payment sum END -->

        <!-- Payer START -->
        <a-form-model-item
          :label="$t('payerFactual')"
          prop="payer_factual"
        >
          <a-input
            v-model="form.payer_factual"
            autocomplete="off"
            @paste="onPaste"
          />
        </a-form-model-item>
        <!-- Payer END -->

        <!-- Invoice number START -->
        <a-form-model-item
          :label="$t('invoiceNumber')"
          prop="code"
        >
          <a-input
            v-model="form.code"
            autocomplete="off"
            @paste="onPaste"
          />
        </a-form-model-item>
        <!-- Invoice number END -->
      </div>

      <a-form-model-item
        :label="$t('comment')"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        prop="comment"
      >
        <a-textarea
          v-model="form.comment"
          autocomplete="off"
        />
      </a-form-model-item>
      <!-- Invoice form END -->

      <div class="drawer-footer">
        <a-button
          key="back"
          @click="handleCancel"
        >
          {{ $t("cancel") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isProcessing"
          @click="handleEditInvoice"
        >
          {{ $t("save") }}
        </a-button>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script setup>
import { watch, nextTick, reactive, ref, computed } from "vue"
import { notification } from "ant-design-vue"
import moment from "moment"
import i18n from "@/i18n"

import getImagePath from "@/utils/getImagePath.js"
import getFormatedSum from "@/utils/getFormatedSum.js"
import { IMAGES_CDN } from "@/constants/common.js"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { updateInvoice } from "@/modules/MPAdmin/services/invoicesService.js"
import StatusRenderer from "./renderers/StatusRenderer.vue"

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true
  },
  invoiceData: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["close", "onUpdate"])

const badPayers = ["АКЦIОНЕРНЕ ТОВАРИСТВО", "Транз.рахунок платежi_ DN, DG, DZ"]

const isProcessing = ref(false)

const formRef = ref()
const form = reactive({
  status: undefined,
  paid_at: undefined,
  payer_factual: undefined,
  code: undefined,
  comment: undefined
})
const formRules = computed(() => ({
  paid_at: [
    { required: true, message: i18n.t("fieldRequire") },
    {
      pattern:
        /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(20)\d{2}[ ]{1}([0-1]?[0-9]|[2][0-3]):([0-5][0-9])$/g,
      message: i18n.t("errorInvoiceDateTime")
    }
  ],
  payment_amount: [{ required: true, message: i18n.t("fieldRequire") }],
  payer_factual: [{ required: true, message: i18n.t("fieldRequire") }],
  code: [{ required: true, message: i18n.t("fieldRequire") }],
  comment: [{ max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]
}))

const onFormChange = () => {
  if (form.status && props.invoiceData) {
    const expire = moment(props.invoiceData.expire_at)
    const now = moment(new Date())

    if (form.status === "PAID" && now.diff(expire, "seconds") > 0) {
      notification.error({
        message: i18n.t("invoiceOverdue")
      })
    }
  }
}

const sellerLogo = computed(() => {
  if (!props.invoiceData?.small_logo) return ""
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(props.invoiceData.small_logo, "full")}`
})

const isPib = computed(() => props.invoiceData.seller_type === "FN")

const showAddress = computed(() => {
  return props.invoiceData.seller_type === "IE" || props.invoiceData.seller_type === "PLC"
})

const handleEditInvoice = async () => {
  const isValid = await formRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  isProcessing.value = true

  let payload = {}

  if (form.status === "PAID") {
    payload = {
      ...form,
      status: form.status,
      paid_at: moment(form.paid_at, "DD.MM.YYYY HH:mm").utc(),
      comment: form.comment ? form.comment : ""
    }
  } else if (props.invoiceData.status === "PAID") {
    payload = {
      status: "PAID",
      comment: form.comment ? form.comment : ""
    }
  } else {
    payload = {
      ...form,
      status: form.status,
      comment: form.comment ? form.comment : ""
    }
  }

  try {
    const { data } = await updateInvoice({ uuid: props.invoiceData.uuid, payload })
    notification.success({ message: i18n.t("invoiceUpdateSuccess") })

    formRef.value.resetFields()
    formRef.value.clearValidate()

    emit("onUpdate", { uuid: props.invoiceData.uuid, ...data })
    emit("close")
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("invoiceUpdateError") })
  } finally {
    isProcessing.value = false
  }
}

const handleCancel = () => {
  formRef.value.resetFields()
  formRef.value.clearValidate()

  emit("close")
}

const onPaste = (event) => {
  const paste = (event.clipboardData || window.clipboardData).getData("text")

  const date = paste.match(
    /((0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0,1,2])\.(20)\d{2}[ ]{1}([0-1]?[0-9]|[2][0-3]):([0-5][0-9]))/gm
  )
  const amount = paste.match(/[ ][\d\s]{1,90}(:?\.\d{2})/gm)
  const payerFactual = paste.match(/(?<=UA\d{27}\n)(\D{1,100})(?=\n)/gm)
  const orderNumber = paste.match(/\d{3}-\d{5,11}/gm)

  nextTick(() => {
    if (date && date.length > 0) {
      event.preventDefault() // prevent double insert
      form.paid_at = date[0]
    }

    if (amount && amount.length > 0) {
      event.preventDefault() // prevent double insert
      form.payment_amount = amount[0].replace(/\s/g, "")
    }

    if (payerFactual && payerFactual.length > 0) {
      // Check if payer include badPayer string
      if (badPayers.findIndex((item) => payerFactual[0].search(item) !== -1) === -1) {
        event.preventDefault() // prevent double insert
        form.payer_factual = payerFactual[0]
      }
    }

    if (orderNumber && orderNumber.length > 0) {
      event.preventDefault() // prevent double insert
      form.code = orderNumber[0]
    }
  })
}

watch(
  () => props.isOpened,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        if (props.invoiceData.status === "PAID") {
          form.comment = props.invoiceData.comment
        } else {
          form.status = props.invoiceData.status
          form.comment = props.invoiceData.comment
        }
      })
    }
  }
)

watch(() => form, onFormChange, { deep: true })
</script>
