<template>
  <div class="seller-payments">
    <a-form-model
      ref="monoTokenFormRef"
      :model="monoTokenForm"
      class="payment-form"
      :rules="tokenRules"
      hideRequiredMark
      @submit.prevent="handleSaveMonoToken"
    >
      <a-divider orientation="left">
        {{ $t("sellerPaymentsCard") }}
      </a-divider>

      <a-form-model-item
        label="monobank token"
        prop="token"
      >
        <a-input v-model="monoTokenForm.token" />
      </a-form-model-item>

      <div class="part-payment-form__footer">
        <a-button
          type="primary"
          html-type="submit"
          :loading="saveProcessingMonoToken"
        >
          {{ $t("save") }}
        </a-button>

        <a-button
          type="danger"
          :loading="deleteProcessingMonoToken"
          @click="handleDeleteMonoToken"
        >
          {{ $t("delete") }}
        </a-button>
      </div>
    </a-form-model>

    <a-form-model
      ref="monoPartPaymentsFormRef"
      :model="monoPartPaymentsForm"
      :rules="monoPartPaymentsFormRules"
      hideRequiredMark
      class="part-payment-form"
      @submit.prevent="handleSaveMonoPartPayments"
    >
      <a-divider orientation="left">
        {{ $t("sellerPaymentsParts", { bank: "monobank" }) }}
      </a-divider>

      <a-form-model-item
        label="API Key"
        prop="api_key"
      >
        <a-input v-model="monoPartPaymentsForm.api_key" />
      </a-form-model-item>

      <a-form-model-item
        label="Store ID"
        prop="store_id"
      >
        <a-input v-model="monoPartPaymentsForm.store_id" />
      </a-form-model-item>

      <a-form-model-item>
        <a-switch v-model="monoPartPaymentsForm.is_active">
          <a-icon
            slot="checkedChildren"
            type="check"
          />
          <a-icon
            slot="unCheckedChildren"
            type="close"
          />
        </a-switch>
        &nbsp;{{ $t("sellerPaymentsPartsAllow", { bank: "monobank" }) }}
      </a-form-model-item>

      <div class="part-payment-form__footer">
        <a-button
          type="primary"
          html-type="submit"
          :loading="saveProcessingmonoPartPayments"
          :disabled="deleteProcessingmonoPartPayments"
        >
          {{ $t("save") }}
        </a-button>
        <a-button
          type="danger"
          :loading="deleteProcessingmonoPartPayments"
          :disabled="saveProcessingmonoPartPayments"
          @click="handleDeleteMonoPartPayments"
        >
          {{ $t("delete") }}
        </a-button>
      </div>
    </a-form-model>

    <a-form-model
      ref="privatPartPaymentsFormRef"
      :model="privatPartPaymentsForm"
      :rules="privatPartPaymentsFormRules"
      hideRequiredMark
      class="part-payment-form"
      @submit.prevent="handleSavePrivatPartPayments"
    >
      <a-divider orientation="left">
        {{ $t("sellerPaymentsParts", { bank: "PrivatBank" }) }}
      </a-divider>

      <a-form-model-item
        label="Store ID"
        prop="store_id"
      >
        <a-input v-model="privatPartPaymentsForm.store_id" />
      </a-form-model-item>

      <a-form-model-item
        label="Recipient ID"
        prop="recipient_id"
      >
        <a-input v-model="privatPartPaymentsForm.recipient_id" />
      </a-form-model-item>

      <a-form-model-item
        label="Password"
        prop="password"
      >
        <a-input v-model="privatPartPaymentsForm.password" />
      </a-form-model-item>

      <a-form-model-item prop="is_active">
        <a-switch v-model="privatPartPaymentsForm.is_active">
          <a-icon
            slot="checkedChildren"
            type="check"
          />
          <a-icon
            slot="unCheckedChildren"
            type="close"
          />
        </a-switch>
        &nbsp;{{ $t("sellerPaymentsPartsAllow", { bank: "PrivatBank" }) }}
      </a-form-model-item>

      <div class="part-payment-form__footer">
        <a-button
          type="primary"
          html-type="submit"
          :loading="saveProcessingPrivatPartsPayments"
        >
          {{ $t("save") }}
        </a-button>
        <a-button
          type="danger"
          :loading="deleteProcessingPrivatPartsPayments"
          @click="handleDeletePrivatPartPayments"
        >
          {{ $t("delete") }}
        </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import {
  fetchPaymentServiceData,
  addPaymentServiceData,
  deletePaymentServiceData
} from "@/modules/MPAdmin/services/sellersService"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { notification } from "ant-design-vue"
import { computed, onMounted, reactive, ref } from "vue"

const props = defineProps({
  sellerSlug: {
    type: String,
    default: ""
  }
})

const requiredRule = computed(() => ({ required: true, message: i18n.t("emptyError") }))

// MONO TOKEN
const monoTokenFormRef = ref()

const saveProcessingMonoToken = ref(false)
const deleteProcessingMonoToken = ref(false)
const monoTokenForm = reactive({
  token: ""
})

const tokenRules = computed(() => ({
  token: requiredRule.value
}))

const getMonoToken = async () => {
  try {
    const { data } = await fetchPaymentServiceData({ slug: props.sellerSlug, service: "mono" })

    monoTokenForm.token = data.token
  } catch (error) {
    if (error?.response?.data?.code === 404) return
    notifyResponseError({ error })
  }
}

const handleSaveMonoToken = async () => {
  const isValid = await monoTokenFormRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  try {
    saveProcessingMonoToken.value = true

    const payload = { token: monoTokenForm.token }

    await addPaymentServiceData({ sellerSlug: props.sellerSlug, payload, service: "mono" })
    notification.success({
      message: i18n.t("tokenSaved")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    saveProcessingMonoToken.value = false
  }
}

const handleDeleteMonoToken = async () => {
  try {
    deleteProcessingMonoToken.value = true

    await deletePaymentServiceData({ slug: props.sellerSlug, service: "mono" })

    monoTokenForm.token = ""

    notification.success({
      message: i18n.t("tokenDeleted")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    deleteProcessingMonoToken.value = false
  }
}

// MONO PARTS
const monoPartPaymentsFormRef = ref()

const saveProcessingmonoPartPayments = ref(false)
const deleteProcessingmonoPartPayments = ref(false)
const monoPartPaymentsForm = reactive({
  api_key: "",
  store_id: "",
  is_active: false
})

const monoPartPaymentsFormRules = computed(() => ({
  store_id: requiredRule.value,
  api_key: requiredRule.value
}))

const getMonoPartPayments = async () => {
  try {
    const { data } = await fetchPaymentServiceData({
      slug: props.sellerSlug,
      service: "mono_parts"
    })

    monoPartPaymentsForm.api_key = data.api_key
    monoPartPaymentsForm.store_id = data.store_id
    monoPartPaymentsForm.is_active = data.is_active
  } catch (error) {
    if (error?.response?.data?.code === 404) return
    notifyResponseError({ error })
  }
}

const handleSaveMonoPartPayments = async () => {
  const isValid = await monoPartPaymentsFormRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  try {
    saveProcessingmonoPartPayments.value = true

    await addPaymentServiceData({
      sellerSlug: props.sellerSlug,
      payload: monoPartPaymentsForm,
      service: "mono_parts"
    })
    notification.success({
      message: i18n.t("monoPartPaymentsSaved")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    saveProcessingmonoPartPayments.value = false
  }
}

const handleDeleteMonoPartPayments = async () => {
  try {
    deleteProcessingmonoPartPayments.value = true

    await deletePaymentServiceData({ slug: props.sellerSlug, service: "mono_parts" })

    monoPartPaymentsForm.api_key = ""
    monoPartPaymentsForm.store_id = ""
    monoPartPaymentsForm.is_active = false

    notification.success({
      message: i18n.t("monoPartPaymentsDeleted")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    deleteProcessingmonoPartPayments.value = false
  }
}

// PRIVAT PARTS
const privatPartPaymentsFormRef = ref()

const saveProcessingPrivatPartsPayments = ref(false)
const deleteProcessingPrivatPartsPayments = ref(false)
const privatPartPaymentsForm = reactive({
  store_id: "",
  recipient_id: "",
  password: "",
  is_active: false
})

const privatPartPaymentsFormRules = computed(() => ({
  store_id: requiredRule.value,
  password: requiredRule.value
}))

const getPrivatPartPayments = async () => {
  try {
    const { data } = await fetchPaymentServiceData({
      slug: props.sellerSlug,
      service: "privat_hire_purchase"
    })

    privatPartPaymentsForm.store_id = data.store_id
    privatPartPaymentsForm.recipient_id = data.recipient_id
    privatPartPaymentsForm.password = data.password
    privatPartPaymentsForm.is_active = data.is_active
  } catch (error) {
    if (error?.response?.data?.code === 404) return
    notifyResponseError({ error })
  }
}

const handleSavePrivatPartPayments = async () => {
  const isValid = await privatPartPaymentsFormRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  try {
    saveProcessingPrivatPartsPayments.value = true

    await addPaymentServiceData({
      sellerSlug: props.sellerSlug,
      payload: privatPartPaymentsForm,
      service: "privat_hire_purchase"
    })
    notification.success({
      message: i18n.t("monoPartPaymentsSaved")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    saveProcessingPrivatPartsPayments.value = false
  }
}

const handleDeletePrivatPartPayments = async () => {
  try {
    deleteProcessingPrivatPartsPayments.value = true

    await deletePaymentServiceData({ slug: props.sellerSlug, service: "privat_hire_purchase" })

    privatPartPaymentsForm.store_id = ""
    privatPartPaymentsForm.recipient_id = ""
    privatPartPaymentsForm.password = ""
    privatPartPaymentsForm.is_active = false

    notification.success({
      message: i18n.t("monoPartPaymentsDeleted")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    deleteProcessingPrivatPartsPayments.value = false
  }
}

onMounted(() => {
  getMonoToken()
  getMonoPartPayments()
  getPrivatPartPayments()
})
</script>

<style lang="scss" scoped>
.seller-payments {
  display: flex;
  flex-flow: row wrap;
  gap: 32px;
}

.payment-form {
  width: 100%;
}

.part-payment-form {
  width: calc(50% - 16px);

  &__footer {
    display: flex;
    gap: 8px;
  }
}
</style>
