import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { API_V2 } from "@/utils/HttpUtils.js"
import { MPA_PREFIX } from "./commonService.js"

export const fetchReturns = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/returns${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    { signal }
  )
}

export const fetchReturnItem = (id) => {
  return API_V2.get(`${MPA_PREFIX}/returns/${id}`)
}

export const fetchAllReasons = () => {
  return API_V2.get(`${MPA_PREFIX}/return-reasons`)
}

export const updateReturn = (id, data) => {
  return API_V2.patch(`${MPA_PREFIX}/returns/${id}`, data)
}
