<template>
  <div class="locale-switcher">
    <a-radio-group v-model="$root.$i18n.locale">
      <a-radio-button
        v-for="lang in locales"
        :key="`locale-${lang.label}`"
        :value="lang.value"
        @click.prevent="changeLanguage(lang.value)"
      >
        {{ lang.label }}
      </a-radio-button>
    </a-radio-group>
  </div>
</template>

<script>
import locales from "@/constants/locales.js"
import Cookies from "js-cookie"

export default {
  name: "LangSwitcher",

  data() {
    return {
      locales
    }
  },

  methods: {
    changeLanguage(value) {
      this.$root.$i18n.locale = value
      Cookies.set("language", value)

      // API.defaults.headers['Accept-language'] = value
    }
  }
}
</script>

<style>
.locale-switcher {
  margin-left: auto;
  margin-right: 24px;
}
</style>
