import { computed, h } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("reviewDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      fixed: "left",
      width: 110
    },
    {
      key: "p_code",
      title: i18n.t("p_code"),
      scopedSlots: {
        customRender: "pCode"
      },
      sorter: true,
      fixed: "left",
      width: 110
    },
    {
      key: "author_id",
      dataIndex: "author.id",
      title: i18n.t("authorID"),
      width: 100,
      sorter: true
    },
    {
      key: "customer_username",
      dataIndex: "author.username",
      title: i18n.t("user"),
      width: 150
    },
    {
      key: "customer_contacts",
      dataIndex: "author",
      title: h("div", ["Email", h("br"), i18n.t("phoneNumber")]),
      scopedSlots: {
        customRender: "contacts"
      },
      width: 200
    },
    {
      key: "rating",
      dataIndex: "rating",
      title: i18n.t("grade"),
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      width: 100,
      sorter: true
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: i18n.t("comment"),
      scopedSlots: {
        customRender: "comment"
      },
      width: 300
    },
    {
      key: "videos",
      dataIndex: "attachments",
      title: i18n.t("reviewMedia"),
      scopedSlots: {
        customRender: "mediaRenderer"
      },
      width: 240
    },
    {
      key: "product_images",
      dataIndex: "product_images",
      title: i18n.t("productImages"),
      width: 80,
      scopedSlots: {
        customRender: "imageRenderer"
      }
    },
    {
      key: "ordering",
      dataIndex: "ordering",
      title: i18n.t("sorting"),
      sorter: true,
      width: 90
    },
    {
      key: "parent",
      dataIndex: "parent",
      title: i18n.t("parent"),
      scopedSlots: {
        customRender: "parent"
      },
      width: 80
    },
    {
      key: "moderation_status",
      dataIndex: "moderation_status",
      title: i18n.t("moderationStatus"),
      scopedSlots: {
        customRender: "statusRender"
      },
      fixed: "right",
      width: 150
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 65
    }
  ])

  return columns
}
