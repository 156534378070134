import i18n from "@/i18n"
import getFormatedSum from "@/utils/getFormatedSum"
import { computed } from "vue"

export default () => {
  const columns = computed(() => {
    return [
      {
        key: "index",
        dataIndex: "index",
        title: "№",
        width: 30,
        customRender: (_, __, index) => index + 1
      },
      {
        key: "product.images",
        dataIndex: "images_of_product",
        title: i18n.t("productImages"),
        width: 80,
        scopedSlots: {
          customRender: "photoRenderer"
        }
      },
      {
        key: "images",
        dataIndex: "images_of_offer",
        title: i18n.t("offerImages"),
        width: 80,
        scopedSlots: {
          customRender: "photoRenderer"
        }
      },
      {
        key: "code",
        width: 160,
        scopedSlots: {
          title: "codeTitleRenderer",
          customRender: "codeRenderer"
        }
      },
      {
        key: "product",
        title: i18n.t("orderProductInfo"),
        scopedSlots: {
          customRender: "productNameRenderer"
        }
      },
      {
        key: "warranty",
        dataIndex: "item_in_order.warranty",
        title: i18n.t("warrantyMonth"),
        width: 140
      },
      {
        key: "quantity",
        dataIndex: "quantity",
        title: i18n.t("count"),
        width: 140
      },
      {
        key: "price",
        dataIndex: "item_in_order.price_per_item",
        title: `${i18n.t("price")}, ${i18n.t("uah")}.`,
        customRender: (sum) => getFormatedSum(sum, true),
        width: 140
      },
      {
        key: "discount",
        dataIndex: "item_in_order.price_per_item",
        title: `${i18n.t("discount")}, ${i18n.t("uah")}.`,
        scopedSlots: { customRender: 'discount' },
        width: 140
      },
      {
        key: "price_total",
        dataIndex: "price_total",
        title: `${i18n.t("sum")}, ${i18n.t("uah")}.`,
        customRender: (sum) => getFormatedSum(sum, true),
        width: 140
      }
    ]
  })

  return columns
}
