import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const columns = computed(() => {
    return [
      {
        key: "index",
        dataIndex: "index",
        title: "№",
        width: 30,
        customRender: (_, __, index) => index + 1
      },
      {
        key: "created_at",
        dataIndex: "created_at",
        title: i18n.t("editDate"),
        width: 120,
        scopedSlots: { customRender: "dateRenderer" }
      },
      {
        key: "created_by",
        title: i18n.t("editAuthor"),
        scopedSlots: {
          customRender: "editAuthorRenderer"
        }
      },
      {
        key: "edit_object",
        title: i18n.t("editObject"),
        scopedSlots: {
          customRender: "editObjectRenderer"
        }
      }
    ]
  })

  return columns
}
