<template>
  <a-drawer
    width="80%"
    :body-style="{ paddingBottom: '80px' }"
    :visible="isOpened"
    :title="`${$t('sellerEditing')}: ${defaultSellerName || '...'} / Slug: ${
      defaultSellerSlug || '...'
    }`"
    @close="handleCloseModal"
  >
    <a-spin
      :spinning="fetching"
      :tip="`${$t('loading')}...`"
    >
      <div style="display: flex; justify-content: space-between">
        <a-tabs v-model="activeTab">
          <a-tab-pane key="general">
            <template #tab>
              <a-icon type="setting" />
              {{ $t("generalInformation") }}
            </template>
          </a-tab-pane>
          <a-tab-pane key="payments">
            <template #tab>
              <a-icon type="dollar" />
              {{ $t("sellerPayments") }}
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>

      <div v-if="isOpened">
        <SellerEditGeneralForm
          ref="generalFormRef"
          v-show="activeTab === 'general'"
          :sellerData="sellerOldData"
          @fieldChange="handleChangeIsEdited(true)"
        />

        <SellerPaymentsForm
          v-show="activeTab === 'payments'"
          :seller-slug="defaultSellerSlug"
        />
      </div>
    </a-spin>

    <div class="drawer-footer">
      <a-button
        key="cancel"
        @click="handleCloseModal"
        :style="{ marginRight: '8px' }"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        key="save"
        type="primary"
        :loading="saveProcessing"
        @click="handleSave"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { nextTick, ref, shallowRef, watch } from "vue"
import { Modal, notification } from "ant-design-vue"

import SellerEditGeneralForm from "./components/SellerEditGeneralForm.vue"
import SellerPaymentsForm from "./components/SellerPaymentsForm.vue"

import { fetchSellerBySlug, updateSeller } from "@/modules/MPAdmin/services/sellersService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import i18n from "@/i18n"

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true
  },
  defaultSellerSlug: {
    type: String,
    default: ""
  },
  defaultSellerName: {
    type: String,
    default: ""
  }
})
const emit = defineEmits(["close", "updated"])

const fetching = ref(false)
const isEdited = ref(false)
const activeTab = ref("general")
const sellerOldData = ref()
const handleChangeIsEdited = (value) => {
  isEdited.value = value
}
const handleCloseModal = () => {
  if (isEdited.value) {
    Modal.confirm({
      title: i18n.t("changesNotSaved"),
      content: i18n.t("leavePage"),
      cancelText: i18n.t("no"),
      okText: i18n.t("yes"),
      onOk: onClose
    })
  } else {
    onClose()
  }
}

const onClose = () => {
  emit("close")

  isEdited.value = false
  activeTab.value = "general"
}

const getSeller = async () => {
  try {
    fetching.value = true

    const { data } = await fetchSellerBySlug(props.defaultSellerSlug)

    sellerOldData.value = data
    nextTick(() => generalFormRef.value.resetForm())
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    fetching.value = false
  }
}

const generalFormRef = ref()
const saveProcessing = shallowRef(false)

const handleSave = async () => {
  const values = await generalFormRef.value.getForm()
  if (!values) return

  saveProcessing.value = true

  const formData = new FormData()

  formData.append("mobile_phone_public", values.mobile_phone_public)
  formData.append("offers_visibility", values.offers_visibility)
  formData.append("hidden_offers_message", values.hidden_offers_message)
  formData.append("is_backoffice_available", values.is_backoffice_available)
  formData.append("is_active", values.is_active)
  formData.append("health", values.health)
  formData.append("is_bonuses_available", values.is_bonuses_available)
  formData.append("allow_synthetic_delivery_method", values.allow_synthetic_delivery_method)
  formData.append("max_bonuses_percent", values.max_bonuses_percent)
  formData.append("max_bonuses_percent", values.max_bonuses_percent)
  formData.append("description", values.description)

  values.users.forEach((user) => {
    formData.append("users", user)
  })
  values.notify_emails.forEach((email) => {
    formData.append("notify_emails", email)
  })

  // If old owner !== new owner
  if (sellerOldData.value?.owner?.uuid !== values.owner) {
    formData.append("owner", values.owner)
  }

  // If old name !== new name
  if (sellerOldData.value?.name !== values.name) {
    formData.append("name", values.name)
  }

  // If logo changed
  if (values.small_logo) {
    formData.append("small_logo", values.small_logo)
  }

  try {
    const { data } = await updateSeller({ slug: props.defaultSellerSlug, formData })
    sellerOldData.value = data
    handleChangeIsEdited(false)
    generalFormRef.value.resetForm(data)

    notification.success({
      message: i18n.t("sellerUpdatingSuccess")
    })
    emit("updated", data)
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    saveProcessing.value = false
  }
}

watch(
  () => props.isOpened,
  (newValue) => {
    if (newValue) {
      getSeller()
    } else {
      sellerOldData.value = undefined
    }
  }
)
</script>
