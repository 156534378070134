export default {
  // Merchant and sellers list
  stockStatus: {
    all: "Всі пропозиції"
  },
  cpaStatus: {
    placeholder: "Тип реклами",
    nocpa: "Без реклами",
    balanceIsOver: "Баланс вичерпаний"
  },
  searchTags: {
    ga_label: "merch0 Custom label, Google",
    ga_merch4: "merch4 Custom label, Google",
    fb_label: "Custom label, Facebook",
    cs_code: "CS-код",
    title: "Назва товару",
    seller_slug: "Продавець",
    category_path: "Категорія"
  },
  facebookChangePriceList: {
    none: "Не змінювати",
    add: "Додати усі вибрані",
    remove: "Видалити усі вибрані"
  },
  facebookPriceList: "Fb прайс-лист",
  changeGoogleLabelMessage:
    "Зміни custom label Google обраних оферів ніяк не вплинуть на custom label Facebook",
  facebookPriceListOptions: {
    none: "Серед обраних оферів жоден не додається та не видаляється з прайс-листа Fb. Якщо вони були в прайс-листі Fb, то залишаються та отримують новий custom label. Якщо не були - не з'являться у прайс-листі але отримають новий custom label",
    add: "Усі обрані пропозиції будуть додані в прайс-лист Fb український та російський",
    remove: "Усі обрані пропозиції будуть видалені з прайс-листа Fb український та російський"
  },
  facebookPriceListError:
    "У вибраних оферів не вказаний custom label Fb, тому їх додати в прайс лист неможливо. Для початку вкажіть custom label",
  chooseOrDragForUpload: "Клацніть або перетягніть файл у цю область, щоб завантажити файл",
  massiveFileChangeError: "Відбулась помилка при масовій зміні",
  additionalButtons: {
    addToBlackList: "Додати в ЧС",
    selectCl: "Встановити CL"
  },
  popOver: {
    accountHealth: "Здоров'я аккаунту",
    orderCount: "Кількість замовлень",
    orderSuccess: "Успішно закритих в кількості",
    orderSuccessSum: "Успішно закритих в прибутку",
    tariffExpire: "Дата закінчення підписки",
    dateAdded: "Дата додання в рекламу"
  },
  table: {
    price: "Ціна",
    inStockShort: "В н-ті",
    clicks: {
      short: "Кл.",
      full: "Кліків"
    },
    middlePrice: {
      short: "С.ц.",
      full: "Середня ціна кліку"
    },
    cost: {
      short: "В.р.",
      full: "Вартість реклами"
    },
    conversion: {
      short: "Ц.к-ї",
      full: "Цінність конверсії"
    },
    soldCount: {
      short: "Пр.",
      full: "Продано одиниць"
    },
    soldPrice: {
      short: "Пр. грн",
      full: "Продано в грн"
    },
    offers: {
      short: "Пр.",
      full: "Пропозицій"
    },
    orders: {
      short: "Зам.",
      full: "Замовлень"
    },
    shows: {
      short: "Пок.",
      full: "Показів"
    },
    lostShows: {
      short: "Втр.",
      full: "втрачених показів (рейтинг)"
    },
    dateExpires: {
      short: "Підп. до",
      full: "Підписка до"
    },
    dateAdded: {
      short: "Добав.",
      full: "Дата добавлення в рекламу"
    },
    leftOver: {
      short: "Залиш.",
      full: "Залишилось"
    },
    allOfOrders: {
      title: "Всіх замовлень",
      percent: "% успішних замовлень"
    }
  },
  states: {
    all: "Всі стани"
  },
  defaultCLabel: "Базовий C-label",
  shopBalance: "Баланс магазину",
  on_hold: "Загал. холд",
  leftover: "Доступно",
  applied_total: "До списання",
  pending_total: "На перевірці",
  canceled_total: "До відхилення",
  balanceHistory: "Історія балансу",
  creatingReport: "Формування звіту",
  writtenOff: "Списано",
  balanceCreatedAt: "Дата зміни",
  balanceBefore: "Баланс до",
  balanceAfter: "Баланс після",
  billingCode: "Білінг-код",
  billingCodeSeparatedByComma: "Перелік білінг-кодів через кому, пробіл або Enter",
  billingCodeError: "Неправильно введені білінг-коди",
  createReport: "Сформувати",
  addToBalance: "Додати до балансу",
  takeFromBalance: "Відняти від балансу",
  dateWriteOff: "Дата списання",
  dateOrdered: "Дата замовлення",
  feedLogic: "У фід мерчанту потрапляють усі пропозиції даного продавця (не стандартна логіка)",
  feedLogicFacebook: "У фід ФБ потрапляють усі пропозиції даного продавця (не стандартна логіка)",
  feedLogicEkatalog: "У фід ЕК потрапляють усі пропозиції даного продавця (не стандартна логіка)",
  feedConditionLogicLabel: "Перетворити Уцінку в New (GA)",
  standartLogic: "Стандартна логіка",
  allOffers: "Усі офери",
  feedLogicPlaceholder: "Потрапляють у фід",
  massiveCLChange: "Масова зміна c-label",
  massiveFileCLChange: "Масова зміна (файл)",
  chooseForMassiveChange: "Виберіть для масової зміни",
  fileFormatForMassiveChange: "Формат файлу зміни custom label",
  massiveCLInput: "Масове введення C-label",
  massiveChangeWithFileStarted: "Массова зміна (файл) почалась",
  massiveChangeWithFileSuccess: "Масова зміна (файл) успішно відбулась",
  customLabelChange: "Змінити custom label",
  customLabelChangeGoogle: "Змінити custom label Google",
  customLabelChangeFacebook: "Змінити custom label Facebook",
  activityChangeFacebook: "Змінити активність Facebook",
  activityChangeEkatalog: "Змінити активність E-katalog",
  facebook_marks: "Активність Fb",
  ekatalog_marks: "Активність Ek",

  merchantFileChangeAlerts: {
    ekatalog_marks: ["Обрані офери будуть додані або видалені з прайс листа Ek"],
    facebook_marks: ["Обрані офери будуть додані або видалені з прайс листа Fb"],
    ga_default_label: [
      "Обраним оферам буде змінено custom label",
      "",
      "Рекомендована кількість оферів в одному файлі не більше 50 тисяч"
    ],
    ga_merch4: [
      "Обраним оферам буде змінено custom label",
      "",
      "Рекомендована кількість оферів в одному файлі не більше 50 тисяч"
    ],
    fb_default_label: [
      "Серед обраних оферів жоден не додається та не видаляється з прайс-листа Fb",
      "Якщо вони були в прайс-листі Fb, то залишаються та отримують новий custom label",
      "Якщо не були - не з'являться у прайс-листі але отримають новий custom label",
      "",
      "Рекомендована кількість оферів в одному файлі не більше 50 тисяч"
    ]
  },
  transactionType: "Тип транзакції",

  transactionTypes: {
    replenishment: "Поповнення балансу адміністрацією",
    bonusesReplenishment: "Поповнення балансу продавцем бонусами",
    writeOff: "Списання комісії",
    writeOffBalance: "Списання коштів адміністрацією"
  },

  balanceChanges: "Зміни балансу",
  // order Statuses
  orderStatuses: {
    new: "Новий",
    inProgress: "В процесі",
    approved: "Підтверджено продавцем",
    shipped: "Відвантажено",
    closed: "Успішно закрито",
    seller_approved: "Підтверджено",
    canceled_by_customer: "Скасовано покупцем",
    canceled_by_seller: "Скасовано продавцем",
    canceled_by_supervisor: "Скасовано адміністрацією",
    canceledBy: {
      seller: "Скасовано продавцем",
      customer: "Скасовано покупцем",
      supervisor: "Скасовано адміністрацією"
    }
  },
  clearTags: "Очистити теги",
  sellers: "Продавці",
  sellerList: "Список продавців",
  sellerState: "Стан продавця",
  // Black list
  blackList: "Чорний список",
  addedToBL: "Додано до чорного списку",
  removeFromBL: "Видалити з чорного списку",
  removedFromBL: "Видалено з чорного списку",
  toBlackList: "в чорний список",
  // Ref
  sellerPlusSlug: "Продавець (слаг)",
  categories: "Категорії",
  brands: "Бренди",
  commission: "Комісія",
  commissionShorted: "Коміс.",
  commissionStatus: "Статус комісії",
  commissionStatusShorted: "Статус ком.",
  reward: "Винагорода",
  rewardShorted: "Вин.",
  rewardStatusShorted: "Статус вин.",
  payments: "Виплати",
  advertismentType: "Тип реклами",
  refuseReason: "Причина відмови",
  billing: "Біллінг",
  changeCommissionStatus: "Зміна статусу комісії",
  reasonCommissionDecline: "Причина відхилення комісії",
  dateOrderUpdate: "Дата оновлення товару",
  dateOfferUpdate: "Дата оновлення замовлення",
  referrer: "Реферал",
  referrers: "Реферали",
  reasonDeclineOrder: "Причина відхилення товару",
  reasonDeclineOffer: "Причина відхилення замовлення",
  refCount: "К-ть рефералів",
  refActiveCount: "К-ть активних рефералів",
  positionsToDebit: "Позицій до списання",
  sumToDebit: "Сума до списання",
  // CPC
  createCampaign: "Створити кампанію",
  creatingCampaign: "Створення кампанії",
  editCampaign: "Редагування кампанії",
  editCampaignCL: "Редагувати Custom label кампанії",
  campaignStatus: "Статус кампанії",
  consumptionYesterday: "Витрати за вчора",
  inc25: "Ріст прибутку >25%",
  inc50: "Ріст прибутку >50%",
  inc100: "Ріст прибутку >100%",
  dec25: "Зниження витрат >25%",
  dec50: "Зниження витрат >50%",
  dec100: "Зниження витрат >100%",
  negativeBalance: "Від'ємний баланс",
  lowBalance: "Вичерпання бюджету завтра",
  searchBySellerSlug: "Шукати за seller slug",
  debitHistory: "Історія списань",
  clearCustomLabel: "Очистити custom label для продавця",
  dateOrder: "Дата замов.",
  codeOrder: "Код замов.",
  statusOrder: "Статус замов."
}
