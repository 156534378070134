<template>
  <div class="attributes-table">
    <div class="attributes-table__actions">
      <a-button @click="addAttributeModalVisible = true">
        {{ $t("addAttribute") }}
      </a-button>

      <a-button
        :disabled="!hasProductType || isLoading"
        @click="handleSortAttributes"
      >
        {{ $t("orderSameAsType") }}
      </a-button>

      <a-button
        type="danger"
        :disabled="!hasProductType || isLoading"
        @click="getProductMainAttributes"
      >
        {{ $t("addMains") }}
      </a-button>

      <a-button
        type="danger"
        :disabled="!hasProductType || isLoading"
        @click="getAllProductTypeAttributes"
      >
        {{ $t("addAllAttributesByType") }}
      </a-button>

      <a-button
        :disabled="isLoading"
        @click="handleDeleteEmptyAttributes"
      >
        {{ $t("deleteBlank") }}
      </a-button>
    </div>

    <a-table
      rowKey="code"
      :columns="columns"
      :dataSource="attributes"
      :loading="isLoading"
      :locale="tableLocale"
      :components="components"
      :pagination="false"
      :scroll="{ x: true }"
    >
      <template
        v-for="(title, key) in tableTitles"
        #[key]
      >
        <TitleTooltip
          :shortText="title.shortText"
          :fullText="title.fullText"
          :key="key"
        />
      </template>

      <template #handleRenderer>
        <a-icon
          class="handle"
          type="menu"
        />
      </template>

      <template #codeRenderer="code">
        <a-button
          type="link"
          style="padding: 0"
          @click="handleOpenAttributeEdit(code)"
        >
          {{ code }}
        </a-button>
      </template>

      <template #valueRenderer="record">
        <EditAttributeValue
          class="attributes-table__value"
          :attribute="record"
          @change="handleAttributeChanged"
        />
      </template>

      <template #typeRenderer="value">
        <a-tooltip
          placement="top"
          :title="value"
        >
          <div class="text-status">
            {{ getFirstCharset(value) }}
          </div>
        </a-tooltip>
      </template>

      <template #booleanTooltipRenderer="value">
        <a-tooltip
          v-if="value === true || value === false"
          placement="top"
          :title="$t(value ? 'yes' : 'no')"
        >
          <div
            class="status"
            :class="[value ? 'status--success' : 'status--error']"
          />
        </a-tooltip>
      </template>

      <template #trustRenderer="value">
        <a-tooltip placement="top">
          <template #title>
            {{ value ? $t(`moderationTrustStatus.${value}`) : "" }}
          </template>
          <div
            class="text-status"
            :class="value"
          >
            {{ getFirstCharset(value) }}
          </div>
        </a-tooltip>
      </template>

      <template #actionsRenderer="_, record, index">
        <a-icon
          type="delete"
          @click="showDeleteAttributeConfirm(record, index)"
        />
      </template>
    </a-table>

    <AddAttributeModal
      :visible="addAttributeModalVisible"
      :product="product"
      :mappedAttributes="attributes"
      @addNewAttribute="handleAddNewAttribute"
      @close="handleCloseAddNewAttribute"
    />
  </div>
</template>

<script setup>
import { computed, provide, ref } from "vue"
import { message, Modal } from "ant-design-vue"
import router from "@/router"
import i18n from "@/i18n"

import TitleTooltip from "@/ant-components/TitleTooltip/index.js"
import TableGraggableWrapper from "@/ant-components/TableGraggableWrapper/index.js"
import EditAttributeValue from "./_components/EditAttributeValue.vue"
import AddAttributeModal from "./_components/AddAttributeModal.vue"

import useProductAttributesColumns from "@/modules/Moderation/ModerationProducts/useProductAttributesColumns.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { fetchAttributesByType } from "@/modules/Moderation/services/moderationProductsService"

const components = {
  body: {
    wrapper: TableGraggableWrapper
  }
}

const props = defineProps({
  mpcCode: {
    type: String,
    required: true
  },
  attributes: {
    type: Array,
    default: () => []
  },
  fetching: {
    type: Boolean,
    default: false
  },
  product: {
    type: Object,
    default: () => ({})
  },
  hasProductType: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  "attributesChanged",
  "addMainAttributes",
  "addAllProductTypeAttributes",
  "addNewAttribute",
  "deleteAttribute",
  "deleteEmptyAttributes",
  "sortAttributes",
  "updateAttribute"
])

const { columns } = useProductAttributesColumns()

const tableLocale = computed(() => ({
  filterConfirm: i18n.t("search"),
  filterReset: i18n.t("reset"),
  emptyText: i18n.t("noData")
}))

const tableTitles = computed(() => ({
  typeTitleRenderer: {
    shortText: "T",
    fullText: i18n.t("type")
  },
  mainTitleRenderer: {
    shortText: "M",
    fullText: i18n.t("attributeIsMain")
  },
  requiredTitleRenderer: {
    shortText: "R",
    fullText: i18n.t("attributeIsRequired")
  },
  statusTitleRenderer: {
    shortText: "S",
    fullText: "Status"
  }
}))

const isLoading = computed(() => props.fetching || isAttributeTableFetching.value)

const onSorted = () => {
  emit("attributesChanged")
}

const handleAttributeChanged = (attribute) => {
  emit("updateAttribute", attribute)
  emit("attributesChanged")
}

const handleAllAttributesChanged = (attributes) => {
  emit("updateAllAttributes", attributes)
  emit("attributesChanged")
}

const getFirstCharset = (text) => text?.charAt(0)

const showDeleteAttributeConfirm = (attr, index) => {
  Modal.confirm({
    title: `${i18n.t("deleteAttributeConfirm")} ${attr.code}?`,
    cancelText: i18n.t("cancel"),
    okText: i18n.t("delete"),
    okType: "danger",
    onOk: () => {
      emit("deleteAttribute", index)
      emit("attributesChanged")
    }
  })
}

const handleOpenAttributeEdit = (code) => {
  const routeData = router.resolve({ name: "EditAttribute", params: { attributeCode: code } })
  window.open(routeData.href, "_blank")
}

// HEADER ACTIONS
const handleDeleteEmptyAttributes = () => {
  emit("deleteEmptyAttributes")
  emit("attributesChanged")
}

const isAttributeTableFetching = ref(false)
const handleSortAttributes = async () => {
  isAttributeTableFetching.value = true

  try {
    const queryParams = { order_by: "order" }

    const { data } = await fetchAttributesByType({
      code: props.product.type.code,
      queryParams
    })

    const sortedAttributes = data.results
      .map(({ code }) => props.attributes.find((item) => item.code === code) || undefined)
      .filter(Boolean)

    handleAllAttributesChanged([
      ...sortedAttributes,
      ...props.attributes.filter(
        ({ code }) => sortedAttributes.findIndex((item) => item.code === code) === -1
      )
    ])
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadAttributesByTypeError") })
  } finally {
    isAttributeTableFetching.value = false
  }
}

const getProductMainAttributes = async () => {
  if (!props.hasProductType) return

  try {
    isAttributeTableFetching.value = true
    const queryParams = {
      order_by: "order",
      is_main: true
    }

    const { data } = await fetchAttributesByType({
      code: props.product.type.code,
      queryParams
    })

    if (!data.results.length) {
      return message.info(i18n.t("mainAttributesIsEmpty"))
    }

    const mainAttrs = data.results.map(({ default_unit, ...attr }) => {
      const existedAttribute = props.attributes.find((item) => attr.code === item.code)
      if (existedAttribute) return existedAttribute

      return {
        ...attr,
        unit: default_unit || {
          code: null,
          value: null
        },
        value: []
      }
    })

    handleAllAttributesChanged([
      ...mainAttrs,
      ...props.attributes.filter(
        (item) => !mainAttrs.some((mainAttr) => mainAttr.code === item.code)
      )
    ])

    handleSortAttributes()
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadMainAttributesError") })
  } finally {
    isAttributeTableFetching.value = false
  }
}

const getAllProductTypeAttributes = async () => {
  if (!props.hasProductType) return

  try {
    isAttributeTableFetching.value = true
    const queryParams = { order_by: "order" }

    const { data } = await fetchAttributesByType({
      code: props.product.type.code,
      queryParams
    })

    if (!data.results.length) {
      return message.info(i18n.t("typeAttributesIsEmpty"))
    }

    const typeAttrs = data.results.map(({ default_unit, ...attr }) => {
      const existedAttribute = props.attributes.find((item) => attr.code === item.code)
      if (existedAttribute) return existedAttribute

      return {
        ...attr,
        unit: default_unit || { code: null, name: null },
        value: []
      }
    })

    handleAllAttributesChanged([
      ...typeAttrs,
      ...props.attributes.filter((item) => !typeAttrs.some(({ code }) => code === item.code))
    ])
    handleSortAttributes()
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("loadAttributesError") })
  } finally {
    isAttributeTableFetching.value = false
  }
}

// ADD ATTRIBUTE
const addAttributeModalVisible = ref(false)
const handleAddNewAttribute = (params) => {
  addAttributeModalVisible.value = false
  emit("addNewAttribute", params)
  emit("attributesChanged")
}
const handleCloseAddNewAttribute = () => {
  addAttributeModalVisible.value = false
}

provide("appData", {
  dataSource: props.attributes
})

provide("onSort", onSorted)
</script>

<style lang="scss" scoped>
.attributes-table {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  &__value {
    width: 600px;
  }

  &__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
  }
}

.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.text-status {
  font-weight: 500;

  &--success {
    color: $green-color;
  }

  &--disabled {
    color: #c6c6c6;
  }

  &--error {
    color: $red-color;
  }
}

.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &--success {
    background-color: #b7eb8f;
  }

  &--error {
    background-color: #ff4d4f;
  }
}
</style>
