import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import { h } from "vue"

const notifyArrayError = ({ message, description, duration = 4.5 }) => {
  notification.error({
    message,
    description: () => h("div", {}, [description.map((item) => h("div", {}, [item]))]),
    duration
  })
}

const notifyObjectError = ({ message, description, duration = 4.5 }) => {
  if (Array.isArray(description)) {
    notifyArrayError({ message, description })
    return
  }

  let descriptionList = []

  if (description?._general) {
    notification.error({
      message,
      description: description._general
    })
  } else if (Object.keys(description)?.length) {
    descriptionList = Object.entries(description).map(([key, value]) => {
      const message = Array.isArray(value) ? value.join(", ") : value
      return `${key}: ${message}`
    })
    notification.error({
      message,
      description: () => h("div", {}, [descriptionList.map((item) => h("div", {}, [item]))]),
      duration
    })
  }
}

const notifyStringError = ({ message, description, duration = 4.5 }) => {
  notification.error({ message, description, duration })
}

export default ({ error, message = i18n.t("error"), duration = 4.5 }) => {
  // Temporary solution before new error responses from v2 API are implemented
  //
  // TODO: remove notifyByDetailType after new error responses are implemented and use notifyObjectError instead
  // Also remove .detail, use .details only
  const description =
    error?.response?.data?.details ||
    error?.response?.data?.detail ||
    error?.response?.data?.message ||
    ""

  if (
    !description &&
    error?.response?.data &&
    typeof error.response.data === "object" &&
    Object.keys(error?.response?.data)?.length
  ) {
    return notifyObjectError({ message, description: error.response.data, duration })
  }

  const notifyByDetailType = {
    object: () => notifyObjectError({ message, description, duration }),
    string: () => notifyStringError({ message, description, duration })
  }

  return notifyByDetailType[typeof description]
    ? notifyByDetailType[typeof description]()
    : notifyByDetailType.string()
}
