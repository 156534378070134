<template>
  <a-drawer
    width="730px"
    :title="$t('additionalButtons.addToBlackList')"
    placement="right"
    :closable="true"
    :visible="addBlackListVisible"
    @close="handleClose"
  >
    <a-radio-group
      :options="options"
      @change="onChangePointBlackList"
    />

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="handleClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        @click="handleConfirm"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
export default {
  name: "AddToBlackList",
  props: {
    addBlackListVisible: {
      type: Boolean,
      default: false
    },
    selectedCsCodes: {
      type: Number,
      default: 1
    },
    selectedPCodes: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      codeKind: "cs_code"
    }
  },
  computed: {
    options() {
      return [
        {
          label: `CS_code (${this.selectedCsCodes})`,
          value: "cs_code"
        },
        {
          label: `P_code (${this.selectedPCodes})`,
          value: "p_code"
        }
      ]
    }
  },
  methods: {
    onChangePointBlackList(e) {
      this.codeKind = e.target.value
    },
    handleConfirm() {
      this.$emit("submit", this.codeKind)
    },
    handleClose() {
      this.$emit("close")
    }
  }
}
</script>
