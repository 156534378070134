import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { ADS_PREFIX } from "./commonServices.js"

export const fetchCPC = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/campaigns/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const createCampaign = (seller_slug, label, balance) => {
  return API_V2.post(`${ADS_PREFIX}/campaigns/`, {
    seller_slug,
    label,
    balance,
    status: "ACTIVE"
  })
}

export const editCampaign = (uuid, data) => {
  return API_V2.patch(`${ADS_PREFIX}/campaigns/${uuid}/`, data)
}

export const fetchCPCAlerts = () => {
  return API_V2.get(`${ADS_PREFIX}/campaigns/alerts/`)
}

export const deleteAlert = (alertUuid) => {
  return API_V2.delete(`${ADS_PREFIX}/campaigns/alerts/${alertUuid}/`)
}

export const getCampaignExpences = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${ADS_PREFIX}/campaigns/${requestParams.uuid.value}/expenses/${getFormatedQueryParams(
      queryParams
    )}`,
    { signal }
  )
}
