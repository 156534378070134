<template>
  <a-spin :spinning="isFetching">
    <a-descriptions
      bordered
      :column="1"
      size="small"
    >
      <a-descriptions-item :label="$t('authorFullname')">
        <b>{{ reviewInfo?.author?.username }}</b>
        {{ reviewInfo?.author?.first_name }}
        {{ reviewInfo?.author?.middle_name }}
        {{ reviewInfo?.author?.last_name }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="reviewInfo?.author?.mobile_phone"
        :label="$t('phoneNumber')"
      >
        <a-button
          type="link"
          style="padding: 0"
          @click="handleCopyText(reviewInfo.author.mobile_phone)"
        >
          +{{ reviewInfo.author.mobile_phone }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('date')">
        {{ createdAt }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('p_code')">
        <a-button
          type="link"
          icon="link"
          :href="productUrl"
          target="_blank"
          style="padding: 0"
        >
          {{ reviewInfo?.p_code }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('grade')">{{ reviewInfo?.rating }}</a-descriptions-item>
      <a-descriptions-item :label="$t('comment')">{{ reviewInfo?.comment }}</a-descriptions-item>

      <a-descriptions-item :label="$t('reviewMedia')">
        <div style="display: flex; gap: 8px">
          <VideoPreviewRenderer :videos="reviewVideos" />
          <TableRendererPhotoGallery
            v-if="reviewImages?.length"
            :images="reviewImages"
          />
        </div>
      </a-descriptions-item>
    </a-descriptions>
  </a-spin>
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import i18n from "@/i18n"
import { message } from "ant-design-vue"
import { IMAGES_CDN, MARKETPLACE } from "@/constants/common"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { fetchProductReview } from "@/modules/MPAdmin/services/productReviewsService"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"
import notifyResponseError from "@/utils/notifyResponseError"
import getImagePath from "@/utils/getImagePath"
import VideoPreviewRenderer from "./VideoPreviewRenderer.vue"

const props = defineProps({
  parent: {
    type: Number,
    required: true
  }
})

const reviewInfo = ref({})
const isFetching = ref(false)

const createdAt = computed(() => {
  return getFormatedDateTime(reviewInfo.value.created_at)
})

const productUrl = computed(() => {
  return `${MARKETPLACE}/product/${reviewInfo.value.slug}/${reviewInfo.value.p_code}/`
})

const handleCopyText = (text) => {
  navigator.clipboard.writeText(text)
  message.info(i18n.t("copied"))
}

const getReview = async () => {
  try {
    isFetching.value = true

    const { data } = await fetchProductReview(props.parent)

    reviewInfo.value = data
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const reviewImages = computed(
  () =>
    reviewInfo.value?.attachments
      ?.filter(({ type }) => type === "IMAGE")
      .map(({ filename }) => filename) || []
)
const reviewVideos = computed(() => {
  return reviewInfo.value?.attachments?.filter(({ type }) => type === "VIDEO") || []
})

onMounted(getReview)
</script>
