<template>
  <div>
    <a-tabs
      v-model="tabPosition"
      type="card"
      style="padding-bottom: 50px"
    >
      <a-tab-pane
        key="common"
        :tab="$t('general')"
        forceRender
      >
        <EditProductCommonTab
          ref="productFormRef"
          :product="product"
          :fetching="productFetching"
          @formChanged="onProductChanged"
          @onTypeChanged="onProductTypeChange"
        />
      </a-tab-pane>
      <a-tab-pane
        key="attributes"
        :tab="$t('attributes')"
        :disabled="!hasProductCode"
      >
        <EditProductAttributesTab
          :mpcCode="productCode"
          :hasProductType="hasProductType"
          :attributes="mappedAttributes"
          :fetching="productFetching"
          :product="product"
          @attributesChanged="onProductChanged"
          @addNewAttribute="handleAddNewAttribute"
          @deleteAttribute="handleDeleteAttribute"
          @deleteEmptyAttributes="handleDeleteEmptyAttributes"
          @updateAttribute="handleUpdateMappedAttributes"
          @updateAllAttributes="setAttributes"
        />
      </a-tab-pane>
      <a-tab-pane
        key="gallery"
        :tab="$t('gallery')"
        :disabled="!hasProductCode"
      >
        <EditProductImagesTab
          :mpc-code="productCode"
          :product="product"
          :fetching="productFetching"
          @onUpdateImages="updateImages"
        />
      </a-tab-pane>
    </a-tabs>

    <div class="drawer-footer">
      <div style="display: flex; align-items: center; gap: 4px">
        {{ $t("productQualityMark.GOOD") }}:
        <a-switch
          :checked="product.quality_mark === 'GOOD'"
          :disabled="!mpcCode"
          :loading="isSaving"
          @change="onQualityStatusChange"
        />
      </div>

      <div style="display: flex; align-items: center; gap: 4px">
        {{ $t("moderationTrustStatus.TRUSTED") }}:
        <a-switch
          :checked="product.trust === 'TRUSTED'"
          :disabled="disableProductTrustChange"
          :loading="isSaving"
          @change="onTrustStatusChange"
        />
      </div>

      <a-button
        v-if="hasProductCode"
        type="primary"
        :disabled="disableProductSave"
        :loading="isSaving"
        @click="onUpdateProduct()"
      >
        {{ $t("save") }}
      </a-button>

      <a-button
        v-else
        type="primary"
        :loading="isCreating"
        @click="onCreateProduct()"
      >
        {{ $t("create") }}
      </a-button>

      <a-button
        v-if="hasProductCode"
        :disabled="!exportIsPossible || isSaving"
        :loading="exportProcessing"
        :class="[exportIsPossible ? 'export-button' : '']"
        @click="handleExport"
      >
        {{ `${$t("save")} + ${$t("export")}` }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, provide, ref } from "vue"
import { notification, Modal } from "ant-design-vue"
import i18n from "@/i18n"

import EditProductCommonTab from "./_components/EditProductCommonTab.vue"
import EditProductAttributesTab from "./_components/EditProductAttributesTab/EditProductAttributesTab.vue"
import EditProductImagesTab from "./_components/EditProductImagesTab/EditProductImagesTab.vue"

import {
  createProduct,
  exportProduct,
  fetchProductRu,
  fetchProductLocalized,
  updateProductLocalized,
  updateProductRu,
  notifyCreateProductError,
  approveProductTrustStatus,
  changeProductQualityMark
} from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { AVAILABLE_FOR_EXPORT_STATUSES, REJECTED_EXPORT_STATUSES } from "@/constants/moderation"
import { formatValuesForRequest } from "./_components/EditProductAttributesTab/_components/utils"

const props = defineProps({
  mpcCode: {
    type: String,
    default: ""
  },
  defaultTabPosition: {
    type: String,
    default: "common"
  },
  languageForDataFetch: {
    type: String,
    default: "ru"
  }
})
const emit = defineEmits(["createProductSuccess", "updateProductSuccess"])

const productFormRef = ref()

const tabPosition = ref(props.defaultTabPosition)

const product = ref({})
const productDataIsChanged = ref(false)

const productCode = computed(() => props.mpcCode || product.value?.code || "")
const hasProductCode = computed(() => Boolean(productCode.value?.length))
const hasProductType = computed(() => Boolean(product.value?.type))
const disableProductTrustChange = computed(() =>
  Boolean(product.value.trust === "TRUSTED" || !props.mpcCode)
)

const disableProductSave = computed(
  () => productFetching.value || exportProcessing.value || !productDataIsChanged.value
)

const onProductChanged = () => {
  productDataIsChanged.value = true
}

// Attributes
const mappedAttributes = ref([])
const setAttributes = (newAttributes) => {
  mappedAttributes.value.splice(0, mappedAttributes.value.length, ...newAttributes)
}

const handleUpdateMappedAttributes = (attribute) => {
  const attrIndex = mappedAttributes.value.findIndex(({ code }) => code === attribute.code)
  if (attrIndex < 0) return
  Object.assign(mappedAttributes.value[attrIndex], attribute)
}

const handleAddNewAttribute = ({ attr, order }) => {
  mappedAttributes.value.splice(+order - 1, 0, attr)
}

const handleDeleteAttribute = (index) => {
  mappedAttributes.value.splice(index, 1)
}

const handleDeleteEmptyAttributes = () => {
  const newAttributes = mappedAttributes.value.filter((item) => {
    return item?.value?.length
  })
  setAttributes(newAttributes)
}

// Update
const isSaving = ref(false)
const onUpdateProduct = async () => {
  const emptyAttrs = mappedAttributes.value.filter(({ value }) => {
    return !value?.length
  })

  if (emptyAttrs.length) {
    return notification.warning({
      message: i18n.t("fillAttributesValueError")
    })
  }

  const [formRu, formUk] = await productFormRef.value.getLocalizedFormValues()
  if (!formRu || !formUk) return

  return handleUpdateProduct(formRu, formUk)
}

const handleUpdateProduct = async (formRu, formUk) => {
  try {
    isSaving.value = true

    const { category, type, ...form } = formRu

    const attributes = formatValuesForRequest(mappedAttributes.value)

    const product = {
      ...form,
      type: type || null,
      attributes,
      synthetic_meta: { category }
    }

    const data = {
      code: productCode.value,
      product
    }

    if (!data.code) {
      notification.error({ message: i18n.t("notUpdated") })
      return
    }

    const response = await Promise.all([
      await updateProductRu(data),
      await updateProductLocalized({ code: data.code, product: formUk })
    ])

    const [{ data: productRu }, { data: productUk }] = response

    product.value = {
      ...productRu,
      public_title: {
        ru: productRu.public_title,
        uk: productUk.public_title
      },
      description: {
        ru: productRu.description,
        uk: productUk.description
      }
    }
    notification.success({ message: i18n.t("updated") })
    productDataIsChanged.value = false
    productFormRef.value?.setFormWatcher()
    emit("updateProductSuccess", {
      ru: {
        ...productRu,
        type_name: productRu.type?.full_name || null,
        brand_name: productRu.brand?.name || null
      },
      uk: {
        ...productUk,
        type_name: productUk.type?.full_name || null,
        brand_name: productUk.brand?.name || null
      }
    })
    return response
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("notUpdated") })
    return null
  } finally {
    isSaving.value = false
  }
}

// Create
const isCreating = ref(false)
const onCreateProduct = async () => {
  const [formRu, formUk] = await productFormRef.value.getLocalizedFormValues()
  if (!formRu || !formUk) return

  handleCreateProduct(formRu, formUk)
}

const handleCreateProduct = async (formRu, formUk) => {
  try {
    isCreating.value = true
    const { category, ...form } = formRu

    const { data } = await createProduct({ ...form, synthetic_meta: { category } })

    await updateProductLocalized({ code: data.code, product: formUk })

    getProduct(data.code)

    notification.success({ message: i18n.t("createProductSuccess") })
    emit("createProductSuccess", data)
  } catch (e) {
    notifyCreateProductError(e)
  } finally {
    isCreating.value = false
  }
}

// Get product
const productFetching = ref(false)
const getProduct = async (code) => {
  try {
    productFetching.value = true

    const productData = await getProductInfo(code)

    product.value = productData

    productDataIsChanged.value = false
    nextTick(() => {
      productFormRef.value?.onResetForm()
    })
    if (!productData?.attributes?.length) return
    setAttributes(productData.attributes)
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("productLoadError") })
  } finally {
    productFetching.value = false
  }
}

const getProductInfo = async (code) => {
  try {
    const params = {
      code: code || props.mpcCode
    }

    const [results, resultsUk] = await Promise.all([
      fetchProductRu(params),
      fetchProductLocalized(params)
    ])

    return Promise.resolve({
      ...results.data,
      public_title: {
        uk: resultsUk.data.public_title || "",
        ru: results.data.public_title || ""
      },
      description: {
        uk: resultsUk.data.description || "",
        ru: results.data.description || ""
      }
    })
  } catch (e) {
    return Promise.reject(e)
  }
}

// Change specific fields
const updateImages = (images) => {
  product.value.images = [...images]
}

const onProductTypeChange = async (type) => {
  product.value.type = type
}

const onTrustStatusChange = async (value) => {
  if (!value) return

  try {
    isSaving.value = true

    await approveProductTrustStatus({ code: props.mpcCode })

    product.value.trust = "TRUSTED"
    emit("updateProductSuccess", { all: { code: productCode.value, trust: product.value.trust } })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSaving.value = false
  }
}

const onQualityStatusChange = async (value) => {
  try {
    isSaving.value = true
    await changeProductQualityMark({ code: props.mpcCode, qualityMark: value ? "GOOD" : null })
    product.value.quality_mark = value ? "GOOD" : null
    emit("updateProductSuccess", {
      all: { code: productCode.value, quality_mark: product.value.quality_mark }
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSaving.value = false
  }
}

// EXPORT
const exportProcessing = ref(false)
const exportIsPossible = computed(
  () => AVAILABLE_FOR_EXPORT_STATUSES.includes(product.value?.status) || productDataIsChanged.value
)
const handleExport = async () => {
  try {
    exportProcessing.value = true

    const updateProductResponse = await onUpdateProduct()
    if (!updateProductResponse) {
      exportProcessing.value = false
      return
    }

    const [updateProductResponseRu] = updateProductResponse

    if (REJECTED_EXPORT_STATUSES.includes(updateProductResponseRu.data.status)) {
      exportProcessing.value = false
      return notification.error({
        message: `${i18n.t("exportError")} ${props.mpcCode}`
      })
    }

    if (!updateProductResponseRu?.data?.code) {
      exportProcessing.value = false
      return notification.error({
        message: i18n.t("exportError")
      })
    }

    await exportProduct(updateProductResponseRu.data.code)

    notification.success({ message: i18n.t("exportSuccess") })
  } catch (error) {
    notifyResponseError({ error, message: `${i18n.t("exportError")} ${props.mpcCode}` })
  } finally {
    exportProcessing.value = false
  }
}

provide("languageForDataFetch", { value: "ru" })

onMounted(() => {
  if (props.mpcCode) {
    getProduct(props.mpcCode)
  } else {
    productFormRef.value?.onResetForm()
  }
})

defineExpose({
  productDataIsChanged
})
</script>

<style lang="scss" scoped>
.export-button {
  background-color: $green-color;
  color: $background-color;
}
</style>
