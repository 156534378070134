import { fetchOrdersMeta } from "@/modules/MPAdmin/services/ordersService.js"

export default {
  namespaced: true,
  state: {
    ordersMetaFetching: false,
    ordersMeta: {
      status_choices_allowed: [],
      status_choices: [],
      moderation_status_choices: [],
      reject_reasons: [],
      reject_reasons_seller: [],
      reject_reasons_moderator: [],
      reject_reasons_buyer: []
    }
  },

  getters: {},

  mutations: {
    ORDERS_META_REQUEST(state, payload) {
      state.fetchOrdersMeta = true
    },
    ORDERS_META_SUCCESS(state, payload) {
      state.fetchOrdersMeta = false
      state.ordersMeta = {
        status_choices_allowed: payload.status_choices_allowed,
        status_choices: payload.status_choices,
        moderation_status_choices: payload.moderation_status_choices,
        reject_reasons: payload.reject_reasons,
        reject_reasons_seller: payload.reject_reasons_seller,
        reject_reasons_moderator: payload.reject_reasons_moderator,
        reject_reasons_buyer: payload.reject_reasons_buyer
      }
    },
    ORDERS_META_FAILURE(state) {
      state.fetchOrdersMeta = false
    }
  },

  actions: {
    fetchOrdersMeta({ commit, state }) {
      if (state.ordersMeta.reject_reasons.length === 0) {
        commit("ORDERS_META_REQUEST")

        return fetchOrdersMeta()
          .then((response) => {
            commit("ORDERS_META_SUCCESS", response.data)
          })
          .catch((err) => {
            commit("ORDERS_META_FAILURE")
          })
      }
    }
  }
}
