<template>
  <div
    v-if="videos.length"
    class="video-attachment-cell"
  >
    <div
      v-for="(video, index) in videos"
      :key="`${video.filename}_${index}`"
      style="position: relative; cursor: pointer"
      @click.prevent="handleOpenVideoModal(video)"
    >
      <a-icon
        class="video-interact-button"
        type="play-circle"
        theme="filled"
      />
      <img
        :alt="video.filename"
        style="width: 50px; object-fit: cover"
        :src="getVideoPoster(video.video_thumbnail)"
      />
    </div>
    <div
      v-if="showVideoModal"
      class="video-wrapper"
      @click="handleCloseVideoModal"
    >
      <video
        controls
        autoplay
        :src="videoSrc"
      />
    </div>
  </div>
</template>

<script setup>
import { IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import { ref, shallowRef } from "vue"

const props = defineProps({
  videos: {
    type: Array,
    default: () => []
  }
})

const showVideoModal = shallowRef(false)
const videoSrc = ref()

const handleOpenVideoModal = (video) => {
  videoSrc.value = `${IMAGES_CDN}/media/assets/videos/${getImagePath(video.filename, "full")}`
  showVideoModal.value = true
}
const handleCloseVideoModal = () => {
  showVideoModal.value = false
  videoSrc.value = undefined
}
const getVideoPoster = (path) => {
  if (!path) return ""
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(path, "full")}`
}
</script>

<style lang="scss" scoped>
.video-attachment-cell {
  display: flex;
  gap: 8px;
}
.video-interact-button {
  position: absolute;
  top: 0;
  left: 0;

  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.7);
  font-size: 20px;

  & + img {
    width: 50px;
    height: 50px;
  }
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $black;

  & > video {
    height: 80%;
    max-width: 80%;

    object-fit: contain;
    background-color: $font-color;
    border-radius: 16px;
  }
}
</style>
