import { API_V2 } from "@/utils/HttpUtils.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"
import { MPA_PREFIX } from "./commonService.js"

export const fetchSellers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/sellers${getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)}`,
    {
      signal
    }
  )
}

export const createSeller = (formData) => {
  return API_V2.post(`${MPA_PREFIX}/sellers`, formData)
}

export const fetchSellerBySlug = (slug) => {
  return API_V2.get(`${MPA_PREFIX}/sellers/${slug}`)
}

export const updateSeller = ({ slug, formData }) => {
  return API_V2.patch(`${MPA_PREFIX}/sellers/${slug}`, formData)
}

export const fetchPaymentToken = ({ slug, code }) => {
  return API_V2.get(`${MPA_PREFIX}/sellers/${slug}/payment-services/${code}`)
}

export const updatePaymentToken = ({ slug, code, data }) => {
  return API_V2.post(`${MPA_PREFIX}/sellers/${slug}/payment-services/${code}`, data)
}

export const deletePaymentToken = ({ slug, code }) => {
  return API_V2.delete(`${MPA_PREFIX}/sellers/${slug}/payment-services/${code}`)
}

export const fetchPaymentServiceData = ({ slug, service }) => {
  return API_V2.get(`${MPA_PREFIX}/sellers/${slug}/payment-services/${service}`)
}

export const addPaymentServiceData = ({ sellerSlug, payload, service }) => {
  return API_V2.post(`${MPA_PREFIX}/sellers/${sellerSlug}/payment-services/${service}`, payload)
}

export const deletePaymentServiceData = ({ slug, service }) => {
  return API_V2.delete(`${MPA_PREFIX}/sellers/${slug}/payment-services/${service}`)
}
